<template>
    <div class="illness-stats-page">
        <portal to="page-title">
            <h1>Sygdoms-statistik</h1>
        </portal>
        <portal to="page-actions">
            <div style="zoom: 0.85">
                <sui-button
                        class="labeled icon button"
                        icon="cog"
                        @click="onEditSettingsButtonClicked()"
                    >
                        Indstillinger
                </sui-button>
            </div>
        </portal>
        <edit-settings-modal
            :isOpen="editSettings"
            :project="null"
            :settings="settingsIL"
            :hasProjectTasks="false"
            :hasTroubleTickets="false"
            page="sygdom"
            :allowShowOverride="false"
        />
        <sui-modal
            v-model="activeFitterModalOpen"
        >
            <sui-modal-header>
                <span>{{ activeFitterIllness.fitterName }}</span>
            </sui-modal-header>
            <sui-modal-content scrolling>
                <IllnessStatsFitter
                    v-if="activeFitterIllness"
                    :fitterIllness="activeFitterIllness"
                    :dataIsReady="activeFitterDataReady"
                    :handledFitterIllness="handledIllnessPeriodsByFitter[activeFitterIllness.fitterSalaryID]"
                    @deepclose="activeFitterModalOpen = false"
                />
            </sui-modal-content>
        </sui-modal>
        <small><em v-if="fromDate && toDate">Viser sygdomsstatistik fra E-komplet for {{ toUserFriendlyDate(fromDate) }} til {{ toUserFriendlyDate(toDate) }}</em></small>
        <sui-segment v-if="settingsIL.get('showOwnIllness')">
            <h2>Egen sygdom</h2>
            <IllnessStatsFitter
                v-if="myIllness"
                :fitterIllness="myIllness"
                :dataIsReady="myIllnessDataReady"
                :handledFitterIllness="handledIllnessPeriodsByFitter[myIllness.fitterSalaryID]"
            />
        </sui-segment>
        <sui-segment v-if="userHasHourCheckRole">
            <div class="row" v-if="newIllnessStatsTotal">
                <div class="col-sm-4">
                    <h2>Ugedag</h2>
                    <Bar 
                        chart-id="total-weekday-chart" 
                        :chart-data="totalWeekdayChartData" 
                        :chart-options="illnessChartOptions"
                    />
                </div>
                <div class="col-sm-4">
                    <h2>Måned</h2>
                    <Bar 
                        chart-id="total-month-chart" 
                        :chart-data="totalMonthChartData" 
                        :chart-options="illnessChartOptions"
                    />
                </div>
                <div class="col-sm-4">
                    <h2>Afdeling</h2>
                    <Bar 
                        chart-id="total-department-chart" 
                        :chart-data="totalDepartmentChartData" 
                        :chart-options="illnessChartOptions"
                    />
                </div>
            </div>
            <h2>Alt sygdom efter montør</h2>
            <sui-table striped selectable v-if="newIllnessStatsTotal">
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Montør</sui-table-header-cell>
                        <sui-table-header-cell>Sygetimer</sui-table-header-cell>
                        <sui-table-header-cell>%Sygetimer</sui-table-header-cell>
                        <sui-table-header-cell>Sygedage</sui-table-header-cell>
                        <sui-table-header-cell>%Sygedage</sui-table-header-cell>
                        <sui-table-header-cell>Uhåndterede <br>Perioder</sui-table-header-cell>
                        <sui-table-header-cell>Afdeling</sui-table-header-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-header-cell>I alt</sui-table-header-cell>
                        <sui-table-header-cell>{{ newIllnessStatsTotal.totalIllnessHours }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ formatPercent(newIllnessStatsTotal.illnessHoursPercentage) }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ newIllnessStatsTotal.totalIllnessDays }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ formatPercent(newIllnessStatsTotal.illnessDaysPercentage) }}</sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="fitter of sortedGroupedIllness" :key="fitter.fitterSalaryID" @click="selectFitterIllness(fitter)" class="clickable" :class="fitter.nonHandledIllnessPeriodsCount >= 3 ? 'dangerRow' : ''">
                        <sui-table-cell>{{ fitter.fitterName }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.totalIllnessHours }}</sui-table-cell>
                        <sui-table-cell>{{ formatPercent(fitter.illnessHoursPercentage) }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.totalIllnessDays }}</sui-table-cell>
                        <sui-table-cell>{{ formatPercent(fitter.illnessDaysPercentage) }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.nonHandledIllnessPeriodsCount }}/{{ fitter.illnessPeriods ? fitter.illnessPeriods.length : 0 }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.department }}</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </sui-segment>
    </div>
</template>
<script>
import EKomplet from '@/lib/DataProviders/EKomplet.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { FitterHourMixin } from '@/lib/Mixins/FitterHourMixin.js'
// import swal from 'sweetalert'
import EventBus from '@/EventBus.js'
import UserRoles from '@/lib/Enums/UserRoles.js'
import IllnessStatsFitter from '../../components/Salary/IllnessStatsFitter.vue'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'
import EditSettingsModal from '@/modules/Settings/EditSettingsModal.vue'
import CachedSettings from '@/modules/Settings/settingsClass'
import { db } from '@/firebase'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const EKompletInstance = new EKomplet()

export default {
    name: 'IllnessStatsPage',
    mixins: [Mixin, DateMixin, FitterHourMixin ],
    components: { IllnessStatsFitter, Bar, EditSettingsModal },
    enums: { UserRoles },

    data() {
        return {
            // selectedDateRange: null,
            fromDate: null,
            toDate: null,
            // selectedSorting: 'totalIllnessHours',
            monthListTemplate: [],
            
            newIllnessStatsGrouped: {},
            newIllnessStatsTotal: null,
            newIllnessStatsByMonth: [],

            activeFitterIllness: {},
            activeFitterModalOpen: false,
            activeFitterDataReady: false,

            myIllnessDataReady: false,
            illnessChartOptions: {
                responsive: true,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            },

            // settingsIL.get('illnessFitterHourCategories'): [56], //TODO: Make user-configurable in settings
            // settingsIL.get('illnessNonBreakingHourCategories'): [56,69,70,75],

            editSettings: false,
            settingsIL: new CachedSettings(),

            handledIllnessPeriods: [], //Populated from Firestore

            // handledIllness: {
            //     KAA: [146085, 146083],
            // },
        };
    },
    methods: {
        clearData() {
            this.fromDate = null;
            this.toDate = null;
            this.newIllnessStatsGrouped = {};
            this.newIllnessStatsTotal = null;
            this.newIllnessStatsByMonth = [];
            this.allIllness = null;
            this.allIllnessGrouped = {};
            this.totalIllnessObj = {
                totalIllnessHours: 0,
                totalIllnessDays: 0,
                illnessByWeekday: [
                    {label: 'Man', value: 0},
                    {label: 'Tirs', value: 0},
                    {label: 'Ons', value: 0},
                    {label: 'Tors', value: 0},
                    {label: 'Fre', value: 0},
                ],
                illnessByDepartment: [],
                illnessByMonth: [],
                illnessDates: [],
                illnesses: [],
            };
            this.activeFitterDataReady = false;
            this.myIllnessDataReady = false;
        },
        async selectDateRange(rangeValue) {
            console.log(`Date range selected: ${rangeValue}`);
            this.clearData();
            let toDate = new Date();
            this.toDate = toDate.setDate(toDate.getDate() - 1);
            let dateVariable = new Date();
            switch (rangeValue) {
                case '1w':
                    dateVariable.setDate(dateVariable.getDate() - 7);
                    break;
                case '1mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 1);
                    break;
                case '3mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 3);
                    break;
                case '6mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 6);
                    break;
                case '12mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 12);
                    break;
            }
            this.fromDate = dateVariable;
            this.generateMonthYearList(dateVariable, toDate);
            console.log(`Getting illness logs for date range from ${dateVariable} to ${toDate}`);
            // this.getMyIllness(dateVariable, toDate).then((result) => {
            //     this.myIllness = result;
            // });
            this.getNewIllnessStats(dateVariable, toDate);
            // if (this.userHasHourCheckRole) {
            //     this.getAllIllness(dateVariable, toDate).then((result) => {
            //         this.allIllness = result;
            //         this.allIllnessGrouped = this.groupIllnessByFitter(result);
            //     });
            // }
        },
        generateMonthYearList(fromDate, toDate) {
            let tempDate = new Date(fromDate);
            let monthYearList = [];
            let i = 0
            while ((tempDate.getMonth() <= toDate.getMonth() || tempDate.getFullYear() < toDate.getFullYear()) && i < 100) { //Max 100 months as safety
                i++
                let monthIdString = `${tempDate.getFullYear()}-${String(tempDate.getMonth()+1).padStart(2, '0')}`
                let monthLabel = `${['N/A', 'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'][tempDate.getMonth()+1]} ${String(tempDate.getFullYear()).substring(2)}`
                monthYearList.push({idString: monthIdString, label: monthLabel, totalHours: 0, illnessHours: 0, totalDays:0, illnessDays: 0, nonIllnessHours: 0, nonIllnessDays: 0})
                tempDate.setMonth(tempDate.getMonth()+1)
            }
            this.monthListTemplate = monthYearList
            this.newIllnessStatsByMonth = monthYearList
        },
        async getNewIllnessStats(fromDate, toDate) {
            EventBus.$emit('function-activity', { functionName: 'illnessStatsPage_getNewIllnessStats', isActive: true });
            let page = 1
            const pageSize = 1000
            let totalObjectCount = 0
            let currentObjectCount = 0
            let query = `fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
            if (!this.userHasHourCheckRole) {
                query += `&searchAttribute=FitterSalaryID&search=${this.userInitials}`
            } else {
                // query += `&searchAttribute=CategoryName&search=SYG`
            }
            query +=`&page=${page}&pageSize=${pageSize}`
            let response = await EKompletInstance.callAPI('get', '/api/v3.0/fitterhours', query)
            console.log(response)
            this.groupIllnessStats(response.data?.data?.[0]?.data)
            totalObjectCount = response.data?.data?.[0].total
            currentObjectCount += response.data?.data?.[0]?.data.length || 0
            let promises = []
            while (currentObjectCount < totalObjectCount && page < 100) { //Max 100 pages
                page++
                // query = `fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}&searchAttribute=FitterSalaryID&search=${this.userInitials}&page=${page}&pageSize=${pageSize}`
                let pageIndex = query.indexOf('&page=')
                query = query.substring(0, pageIndex) + `&page=${page}&pageSize=${pageSize}`
                promises.push(
                    EKompletInstance.callAPI('get', '/api/v3.0/fitterhours', String(query)).then((response) => {
                        console.log(response)
                        return this.groupIllnessStats(response.data?.data?.[0]?.data)
                    })
                )
                currentObjectCount += pageSize
            }
            await Promise.all(promises)
            // console.log(Object.keys(this.newIllnessStatsGrouped).length, ' fitters found')
            for (let month in this.newIllnessStatsByMonth) {
                this.$set(this.newIllnessStatsByMonth[month], 'value', this.newIllnessStatsByMonth[month].illnessHours)
            }
            this.myIllnessDataReady = false
            this.myIllnessDataReady = true
            EventBus.$emit('function-activity', { functionName: 'illnessStatsPage_getNewIllnessStats', isActive: false });
        },
        groupIllnessStats(fitterHoursArray) {
            console.log(`Grouping illness stats for ${fitterHoursArray.length} entries`);
            const fitterTemplate = {
                totalHours: 0,
                totalDays: 0,
                totalHoursByWeekday: [0,0,0,0,0,0,0],
                totalIllnessHours: 0,
                illnessHoursByWeekday: [0,0,0,0,0,0,0],
                illnessHoursPercentageByWeekday: [0,0,0,0,0,0,0],
                illnessHoursPercentage: 0,
                totalIllnessDays: 0,
                illnessDaysPercentage: 0,
                totalNonIllnessHours: 0,
                nonIllnessHoursByWeekday: [0,0,0,0,0,0,0],
                totalNonIllnessDays: 0,
                allDates: [],
                illnessDates: [],
                illnessPeriods: [],
                handledIllnessPeriodsCount: 0,
                nonHandledIllnessPeriodsCount: 0,
                nonIllnessDates: [],
                statsByMonth: this.cloneJson(this.monthListTemplate),
                fitterName: '',
                fitterSalaryID: '',
            };
            const illnessPeriodTemplate = {
                precededByDate: null,
                supersededByDate: null,
                firstIllnessDate: null,
                lastIllnessDate: null,
                illnesses: [],
                illnessDates: [],
                totalIllnessHours: 0,
                illnessPeriodHandled: false,
                illnessPeriodPartiallyHandled: false,
            }
            // console.log(`Number of months: ${this.monthListTemplate.length}`)
            for (let entry of fitterHoursArray) {
                let jsWeekday = this.parseDate(entry.Date).getDay()
                if (jsWeekday === 0) jsWeekday = 7  //Change sunday from 0 to 7
                let weekDayIndex = jsWeekday - 1  //0-indexed weekday starting from monday
                let monthIdString = `${entry.Year}-${String(entry.Month).padStart(2, '0')}`
                let monthIndex = this.monthListTemplate.findIndex(month => month.idString == monthIdString)
                // console.log(`This month: ${monthIdString} (Index ${monthIndex})`)
                let fitterSalaryID = entry.FitterSalaryID.replaceAll(/(\s+)|(\d+)/g, '') //Remove any whitespace and numbers from the fitter ID
                // console.log(`Fitter ID ${entry.FitterSalaryID} -> ${fitterSalaryID}`)

                const entryDate = this.formatMachineDate(entry.Date)
                
                //TOTAL ENTRIES
                //TOTAL HOURS
                if (!this.newIllnessStatsTotal) {
                    this.newIllnessStatsTotal = this.cloneJson(fitterTemplate);
                    this.newIllnessStatsTotal.fitterName = 'Total';
                    this.newIllnessStatsTotal.fitterSalaryID = 'TOTAL';
                    this.newIllnessStatsTotal.byDepartment = {};
                }
                this.$set(this.newIllnessStatsTotal, 'totalHours', (Number(this.newIllnessStatsTotal.totalHours) + Number(entry.Hours)))
                this.$set(this.newIllnessStatsTotal.totalHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.totalHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                if (!this.newIllnessStatsTotal.allDates.includes(entryDate)) {
                    this.newIllnessStatsTotal.allDates.push(entryDate);
                    this.$set(this.newIllnessStatsTotal, 'totalDays', (Number(this.newIllnessStatsTotal.totalDays) + 1))
                    this.$set(this.newIllnessStatsByMonth[monthIndex], 'totalDays', (Number(this.newIllnessStatsByMonth[monthIndex].totalDays) + 1))
                }
                this.$set(this.newIllnessStatsByMonth[monthIndex], 'totalHours', (Number(this.newIllnessStatsByMonth[monthIndex].totalHours) + Number(entry.Hours)))
                //ILLNESS HOURS
                if (this.settingsIL.get('illnessFitterHourCategories').includes(entry.FitterCategoryID)) {
                    this.$set(this.newIllnessStatsTotal, 'totalIllnessHours', (Number(this.newIllnessStatsTotal.totalIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.illnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.illnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.illnessDates.includes(entryDate)) {
                        this.newIllnessStatsTotal.illnessDates.push(entryDate);
                        this.$set(this.newIllnessStatsTotal, 'totalIllnessDays', (Number(this.newIllnessStatsTotal.totalIllnessDays) + 1))
                        this.$set(this.newIllnessStatsByMonth[monthIndex], 'totalIllnessDays', (Number(this.newIllnessStatsByMonth[monthIndex].totalIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsByMonth[monthIndex], 'illnessHours', (Number(this.newIllnessStatsByMonth[monthIndex].illnessHours) + Number(entry.Hours)))
                //NON-ILLNESS HOURS
                } else if (!this.settingsIL.get('illnessNonBreakingHourCategories').includes(entry.FitterCategoryID)) { //Only count non-illness hours if they are not in the non-breaking categories (non-breaking categories do not break a period of illness)
                    this.$set(this.newIllnessStatsTotal, 'totalNonIllnessHours', (Number(this.newIllnessStatsTotal.totalNonIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.nonIllnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.nonIllnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.nonIllnessDates.includes(entryDate)) {
                        this.newIllnessStatsTotal.nonIllnessDates.push(entryDate);
                        this.$set(this.newIllnessStatsTotal, 'totalNonIllnessDays', (Number(this.newIllnessStatsTotal.totalNonIllnessDays) + 1))
                        this.$set(this.newIllnessStatsByMonth[monthIndex], 'nonIllnessDays', (Number(this.newIllnessStatsByMonth[monthIndex].nonIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsByMonth[monthIndex], 'nonIllnessHours', (Number(this.newIllnessStatsByMonth[monthIndex].nonIllnessHours) + Number(entry.Hours)))
                }
                //PERCENTAGES
                this.$set(this.newIllnessStatsTotal, 'illnessHoursPercentage', (this.newIllnessStatsTotal.totalIllnessHours / this.newIllnessStatsTotal.totalHours * 100))
                this.$set(this.newIllnessStatsTotal, 'illnessDaysPercentage', (this.newIllnessStatsTotal.totalIllnessDays / this.newIllnessStatsTotal.totalDays * 100))
                this.$set(this.newIllnessStatsTotal.illnessHoursPercentageByWeekday, weekDayIndex, ((this.newIllnessStatsTotal.illnessHoursByWeekday[weekDayIndex] / this.newIllnessStatsTotal.totalHoursByWeekday[weekDayIndex]) * 100))
                this.$set(this.newIllnessStatsByMonth[monthIndex], 'illnessHoursPercentage', (this.newIllnessStatsByMonth[monthIndex].illnessHours / this.newIllnessStatsByMonth[monthIndex].totalHours * 100))

                //BY DEPARTMENT
                if (!this.newIllnessStatsTotal.byDepartment[entry.Department]) {
                    this.newIllnessStatsTotal.byDepartment[entry.Department] = this.cloneJson(fitterTemplate);
                    this.newIllnessStatsTotal.byDepartment[entry.Department].fitterName = entry.Department;
                    this.newIllnessStatsTotal.byDepartment[entry.Department].fitterSalaryID = entry.Department;
                    this.newIllnessStatsTotal.byDepartment[entry.Department].department = entry.Department;
                }
                //TOTAL HOURS
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalHours) + Number(entry.Hours)))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].totalHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                if (!this.newIllnessStatsTotal.byDepartment[entry.Department].allDates.includes(entryDate)) {
                    this.newIllnessStatsTotal.byDepartment[entry.Department].allDates.push(entryDate);
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalDays) + 1))
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalDays) + 1))
                }
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalHours) + Number(entry.Hours)))
                //ILLNESS HOURS
                if (this.settingsIL.get('illnessFitterHourCategories').includes(entry.FitterCategoryID)) {
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalIllnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.byDepartment[entry.Department].illnessDates.includes(entryDate)) {
                        this.newIllnessStatsTotal.byDepartment[entry.Department].illnessDates.push(entryDate);
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessDays) + 1))
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'illnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].illnessHours) + Number(entry.Hours)))
                //NON-ILLNESS HOURS
                } else if (!this.settingsIL.get('illnessNonBreakingHourCategories').includes(entry.FitterCategoryID)) { //Only count non-illness hours if they are not in the non-breaking categories (non-breaking categories do not break a period of illness)
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalNonIllnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalNonIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessDates.includes(entryDate)) {
                        this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessDates.push(entryDate);
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalNonIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalNonIllnessDays) + 1))
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalNonIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalNonIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'nonIllnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].nonIllnessHours) + Number(entry.Hours)))
                }
                //PERCENTAGES
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'illnessHoursPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessHours / this.newIllnessStatsTotal.byDepartment[entry.Department].totalHours * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'illnessDaysPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessDays / this.newIllnessStatsTotal.byDepartment[entry.Department].totalDays * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursPercentageByWeekday, weekDayIndex, ((this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursByWeekday[weekDayIndex] / this.newIllnessStatsTotal.byDepartment[entry.Department].totalHoursByWeekday[weekDayIndex]) * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'illnessHoursPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].illnessHours / this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalHours * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'illnessDaysPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalIllnessDays / this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalDays * 100))
                
                //BY FITTER
                if (!this.newIllnessStatsGrouped[fitterSalaryID]) { //If the fitter is not already in the object, add them
                    this.newIllnessStatsGrouped[fitterSalaryID] = this.cloneJson(fitterTemplate);
                    this.newIllnessStatsGrouped[fitterSalaryID].fitterName = entry.FitterName;
                    this.newIllnessStatsGrouped[fitterSalaryID].fitterSalaryID = fitterSalaryID;
                    this.newIllnessStatsGrouped[fitterSalaryID].department = entry.Department;
                }
                //TOTAL HOURS
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalHours) + Number(entry.Hours)))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].totalHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                if (!this.newIllnessStatsGrouped[fitterSalaryID].allDates.includes(entryDate)) { //If the date is not already in the array, add it
                    this.newIllnessStatsGrouped[fitterSalaryID].allDates.push(entryDate);
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalDays) + 1))
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalDays) + 1))
                }
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalHours) + Number(entry.Hours)))
                //ILLNESS HOURS
                if (this.settingsIL.get('illnessFitterHourCategories').includes(entry.FitterCategoryID)) { //If the entry is an illness entry, add it to the illness stats
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalIllnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsGrouped[fitterSalaryID].illnessDates.includes(entryDate)) { //If the illness date is not already in the array, add it
                        this.newIllnessStatsGrouped[fitterSalaryID].illnessDates.push(entryDate);
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessDays) + 1))
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'illnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].illnessHours) + Number(entry.Hours)))
                    //ILLNESS PERIODS
                    let illnessPeriodFound = false
                    for (let illnessPeriod of this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods) {
                        if (!illnessPeriod.precededByDate && !illnessPeriod.supersededByDate) { //Illness period is not bounded (yet)
                            illnessPeriodFound = true
                            illnessPeriod.illnesses.push(entry)
                            this.$set(illnessPeriod, 'illnesses', illnessPeriod.illnesses.sort((a, b) => a.Date > b.Date ? -1 : 1))
                            illnessPeriod.totalIllnessHours += Number(entry.Hours)
                            if (!illnessPeriod.illnessDates.includes(entryDate)) {
                                illnessPeriod.illnessDates.push(entryDate)
                                //If this illness is the first or last in the period, set the first or last illness date
                                if (illnessPeriod.firstIllnessDate > entryDate) {
                                    illnessPeriod.firstIllnessDate = entryDate
                                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessPeriods', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.sort((a, b) => a.firstIllnessDate > b.firstIllnessDate ? -1 : 1))
                                } else if (illnessPeriod.lastIllnessDate < entryDate) {
                                    illnessPeriod.lastIllnessDate = entryDate
                                }
                            }
                            break;
                        } else if (illnessPeriod.precededByDate <= entryDate && !illnessPeriod.supersededByDate) { //Illness period is bounded by start date only, and current illness is after that date
                            illnessPeriodFound = true
                            illnessPeriod.illnesses.push(entry)
                            this.$set(illnessPeriod, 'illnesses', illnessPeriod.illnesses.sort((a, b) => a.Date > b.Date ? -1 : 1))
                            illnessPeriod.totalIllnessHours += Number(entry.Hours)
                            if (!illnessPeriod.illnessDates.includes(entryDate)) {
                                illnessPeriod.illnessDates.push(entryDate)
                                //If this illness is the first or last in the period, set the first or last illness date
                                if (illnessPeriod.firstIllnessDate > entryDate) {
                                    illnessPeriod.firstIllnessDate = entryDate
                                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessPeriods', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.sort((a, b) => a.firstIllnessDate > b.firstIllnessDate ? -1 : 1))
                                } else if (illnessPeriod.lastIllnessDate < entryDate) {
                                    illnessPeriod.lastIllnessDate = entryDate
                                }
                            }
                            break;
                        } else if (!illnessPeriod.precededByDate && illnessPeriod.supersededByDate > entryDate) { //Illness period is bounded by end date only, and current illness is before that date
                            illnessPeriodFound = true
                            illnessPeriod.illnesses.push(entry)
                            this.$set(illnessPeriod, 'illnesses', illnessPeriod.illnesses.sort((a, b) => a.Date > b.Date ? -1 : 1))
                            illnessPeriod.totalIllnessHours += Number(entry.Hours)
                            if (!illnessPeriod.illnessDates.includes(entryDate)) {
                                illnessPeriod.illnessDates.push(entryDate)
                                //If this illness is the first or last in the period, set the first or last illness date
                                if (illnessPeriod.firstIllnessDate > entryDate) {
                                    illnessPeriod.firstIllnessDate = entryDate
                                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessPeriods', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.sort((a, b) => a.firstIllnessDate > b.firstIllnessDate ? -1 : 1))
                                } else if (illnessPeriod.lastIllnessDate < entryDate) {
                                    illnessPeriod.lastIllnessDate = entryDate
                                }
                            }
                            break;
                        } else if (illnessPeriod.precededByDate <= entryDate && illnessPeriod.supersededByDate >= entryDate) { //Illness period is bounded by both start and end date, and current illness is within that period
                            illnessPeriodFound = true
                            illnessPeriod.illnesses.push(entry)
                            this.$set(illnessPeriod, 'illnesses', illnessPeriod.illnesses.sort((a, b) => a.Date > b.Date ? -1 : 1))
                            illnessPeriod.totalIllnessHours += Number(entry.Hours)
                            if (!illnessPeriod.illnessDates.includes(entryDate)) {
                                illnessPeriod.illnessDates.push(entryDate)
                                //If this illness is the first or last in the period, set the first or last illness date
                                if (illnessPeriod.firstIllnessDate > entryDate) {
                                    illnessPeriod.firstIllnessDate = entryDate
                                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessPeriods', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.sort((a, b) => a.firstIllnessDate > b.firstIllnessDate ? -1 : 1))
                                } else if (illnessPeriod.lastIllnessDate < entryDate) {
                                    illnessPeriod.lastIllnessDate = entryDate
                                }
                            }
                            break;
                        }
                    }
                    if (!illnessPeriodFound) { //If no illness period was found, create a new one
                        let newIllnessPeriod = this.cloneJson(illnessPeriodTemplate)
                        newIllnessPeriod.illnesses.push(entry) //Add the illness entry to the period
                        this.$set(newIllnessPeriod, 'illnesses', newIllnessPeriod.illnesses.sort((a, b) => a.Date > b.Date ? -1 : 1))
                        newIllnessPeriod.totalIllnessHours += Number(entry.Hours) //Add the hours to the total illness hours
                        newIllnessPeriod.illnessDates.push(entryDate) //Add the date to the illness dates array
                        newIllnessPeriod.firstIllnessDate = entryDate
                        newIllnessPeriod.lastIllnessDate = entryDate
                        
                        //precededByDate should be set to the maximum date of nonIllnessDates before the current illness entry
                        let nonIllnessDatesBefore = this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessDates.filter(date => date <= entryDate)
                        if (nonIllnessDatesBefore.length) {
                            newIllnessPeriod.precededByDate = nonIllnessDatesBefore.reduce((a, b) => a > b ? a : b)
                        }

                        //supercededByDate should be set to the minimum date of nonIllnessDates after the current illness entry
                        let nonIllnessDatesAfter = this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessDates.filter(date => date > entryDate)
                        if (nonIllnessDatesAfter.length) {
                            newIllnessPeriod.supersededByDate = nonIllnessDatesAfter.reduce((a, b) => a < b ? a : b)
                        }

                        this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.push(newIllnessPeriod)
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'nonHandledIllnessPeriodsCount', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.length)
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessPeriods', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.sort((a, b) => a.firstIllnessDate > b.firstIllnessDate ? -1 : 1))
                    }
                //NON-ILLNESS HOURS
                } else if (!this.settingsIL.get('illnessNonBreakingHourCategories').includes(entry.FitterCategoryID)) { //Only count non-illness hours if they are not in the non-breaking categories (non-breaking categories do not break a period of illness)
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalNonIllnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalNonIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessDates.includes(entryDate)) { //If the non-illness date is not already in the array, add it
                        this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessDates.push(entryDate);
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalNonIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalNonIllnessDays) + 1))
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalNonIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalNonIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'nonIllnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].nonIllnessHours) + Number(entry.Hours)))
                    //ILLNESS PERIODS
                    for (let illnessPeriod of this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods) {
                        //Find out if the current entry is within an illness period, and weather it should break the period, or just limit it
                        if ((illnessPeriod.precededByDate <= entryDate || !illnessPeriod.precededByDate) && (illnessPeriod.supersededByDate >= entryDate) || !illnessPeriod.supersededByDate) { //If the current entry is within the period (bounded or not)
                            //Split illness dates into two arrays, one for the period before the current entry, and one for the period after
                            let illnessDatesBefore = illnessPeriod.illnessDates.filter(date => date < entryDate)
                            let illnessDatesAfter = illnessPeriod.illnessDates.filter(date => date >= entryDate)

                            if (!illnessDatesBefore.length) { //The current (non-illness) entry is before all illness entries in the period
                                illnessPeriod.precededByDate = entryDate
                                break; //No need to check further, as the period is now limited by the current entry, and periods cannot overlap
                            } else if (!illnessDatesAfter.length) { //The current (non-illness) entry is after all illness entries in the period
                                illnessPeriod.supersededByDate = entryDate
                                break; //No need to check further, as the period is now limited by the current entry, and periods cannot overlap
                            } else { //The current (non-illness) entry is between two illness entries in the period, and should therefore split the perid in two

                                //Create a new illness period for the period after the current entry
                                let newIllnessPeriod = this.cloneJson(illnessPeriodTemplate)
                                newIllnessPeriod.precededByDate = entryDate
                                newIllnessPeriod.supersededByDate = illnessPeriod.supersededByDate
                                newIllnessPeriod.firstIllnessDate = illnessDatesAfter.reduce((a, b) => a < b ? a : b)
                                newIllnessPeriod.lastIllnessDate = illnessDatesAfter.reduce((a, b) => a > b ? a : b)
                                newIllnessPeriod.illnesses = illnessPeriod.illnesses.filter(illness => illnessDatesAfter.includes(this.formatMachineDate(illness.Date))).sort((a, b) => a.Date > b.Date ? -1 : 1)
                                newIllnessPeriod.illnessDates = illnessDatesAfter
                                newIllnessPeriod.totalIllnessHours = newIllnessPeriod.illnesses.reduce((acc, illness) => acc + Number(illness.Hours), 0)

                                //Update the current illness period to only include the period before the current entry
                                illnessPeriod.supersededByDate = entryDate
                                illnessPeriod.firstIllnesDate = illnessDatesBefore.reduce((a, b) => a < b ? a : b)
                                illnessPeriod.lastIllnessDate = illnessDatesBefore.reduce((a, b) => a > b ? a : b)
                                illnessPeriod.illnesses = illnessPeriod.illnesses.filter(illness => illnessDatesBefore.includes(this.formatMachineDate(illness.Date))).sort((a, b) => a.Date > b.Date ? -1 : 1)
                                illnessPeriod.illnessDates = illnessDatesBefore
                                illnessPeriod.totalIllnessHours = illnessPeriod.illnesses.reduce((acc, illness) => acc + Number(illness.Hours), 0)
                                this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.push(newIllnessPeriod)
                                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'nonHandledIllnessPeriodsCount', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.length)
                                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessPeriods', this.newIllnessStatsGrouped[fitterSalaryID].illnessPeriods.sort((a, b) => a.firstIllnessDate > b.firstIllnessDate ? -1 : 1))
                            }
                        }
                    }
                }
                //PERCENTAGES
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessHoursPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessHours / this.newIllnessStatsGrouped[fitterSalaryID].totalHours) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessDaysPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessDays / this.newIllnessStatsGrouped[fitterSalaryID].totalDays) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursPercentageByWeekday, weekDayIndex, ((this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursByWeekday[weekDayIndex] / this.newIllnessStatsGrouped[fitterSalaryID].totalHoursByWeekday[weekDayIndex]) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'illnessHoursPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].illnessHours / this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalHours) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'illnessDaysPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalIllnessDays / this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalDays) * 100))
            }

            //Hack to ensure sorted list updates
            // let tempSorting = String(this.selectedSorting)
            // this.selectedSorting = null
            // this.selectedSorting = tempSorting
            this.newIllnessStatsGrouped = this.cloneJson(this.newIllnessStatsGrouped) //Fixes weird Vue reactivity bug
            this.$forceUpdate()

            return true
        },
        selectFitterIllness(fitterIllness) {
            this.activeFitterIllness = fitterIllness
            this.activeFitterDataReady = false
            this.activeFitterDataReady = true
            this.activeFitterModalOpen = true
        },
        formatPercent(value) {
            // return value
            return `${value.toFixed(1)} %`
        },
        onEditSettingsButtonClicked(){
            this.editSettings = true
            console.log("Opening settings modal")
        },

        onSettingUpdate(key) {
            switch (key) {
                case 'illnessTimeInterval': this.selectDateRange(this.settingsIL.get('illnessTimeInterval')) 
                    break
                case 'illnessFitterHourCategories': this.selectDateRange(this.settingsIL.get('illnessTimeInterval')) 
                    break
                case 'illnessNonBreakingHourCategories': this.selectDateRange(this.settingsIL.get('illnessTimeInterval'))
                    break
                default: console.log("An update method have not been implemented for this setting",key)
            }
        }, 
    },
    computed: {
        /**
         * Returns the initials of the logged in user, extracted from the email address
         */
        userInitials() {
            return this.$parent.user?.email?.substring(0, this.$parent.user?.email?.indexOf('@'))?.toUpperCase() || null;
        },
        /**
         * Returns true if the logged in user has the HourCheck role, so they should be allowed to see other users' hours
         */
        userHasHourCheckRole() {
            return this.$parent.firebaseUser?.Roles?.includes(UserRoles.HOUR_CHECK) || false;
        },
        sortedGroupedIllness() {
            let sortBy = this.settingsIL.get('sortIllnessWorkersBy')
            return Object.values(this.illnessStatsGroupedWithFlags).sort((a, b) => {
                if (sortBy == 'fitterName' || sortBy == 'department') {
                    if (a[sortBy] == b[sortBy]) { //Edge case where first choice of sorting is the same
                        return a.totalIllnessHours > b.totalIllnessHours ? -1 : 1;
                    }
                    return a[sortBy] > b[sortBy] ? 1 : -1;
                } else if (sortBy == 'nonHandledIllnessPeriodsCount') {
                    if (a[sortBy] == b[sortBy]) { //Edge case where first choice of sorting is the same
                        if (a.illnessPeriods.length == b.illnessPeriods.length) { //First level fallback to total illness Periods, if nonHandledIllnessPeriodsCount is the same
                            return a.totalIllnessHours > b.totalIllnessHours ? -1 : 1;
                        }
                        return a.illnessPeriods.length > b.illnessPeriods.length ? -1 : 1;
                    }
                    return a[sortBy] > b[sortBy] ? -1 : 1;
                } else if (sortBy == 'illnessPeriods.length') {
                    if (a.illnessPeriods.length == b.illnessPeriods.length) { //Edge case where first choice of sorting is the same
                        if (a.nonHandledIllnessPeriodsCount == b.nonHandledIllnessPeriodsCount) { //First level fallback to nonHandledIllnessPeriodsCount, if illnessPeriods.length is the same
                            return a.totalIllnessHours > b.totalIllnessHours ? -1 : 1;
                        }
                        return a.nonHandledIllnessPeriodsCount > b.nonHandledIllnessPeriodsCount ? -1 : 1;
                    }
                    return a.illnessPeriods.length > b.illnessPeriods.length ? -1 : 1;
                }
                else if (sortBy.includes('.')) {
                    let [sortKey, sortSubKey] = sortBy.split('.')
                    if (a[sortKey][sortSubKey] == b[sortKey][sortSubKey]) { //Edge case where first choice of sorting is the same
                        return a.totalIllnessHours > b.totalIllnessHours ? -1 : 1; //Sort by total illness hours if the same
                    }
                    return a[sortKey][sortSubKey] > b[sortKey][sortSubKey] ? -1 : 1;
                }
                if (a[sortBy] == b[sortBy]) { //Edge case where first choice of sorting is the same
                    return a.totalIllnessHours > b.totalIllnessHours ? -1 : 1;
                }
                return a[sortBy] > b[sortBy] ? -1 : 1;
            });
        },
        totalIllnessHoursByDepartment() {
            let output = []
            for (let department of Object.values(this.newIllnessStatsTotal.byDepartment)) {
                output.push({label: department.department, value: department.totalIllnessHours})
            }
            return output
        },
        myIllness() {
            return this.newIllnessStatsGrouped[this.userInitials] || null;
        },
        totalWeekdayChartData() {
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.newIllnessStatsTotal.illnessHoursPercentageByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.newIllnessStatsTotal.illnessHoursByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: ['man', 'tirs', 'ons', 'tors', 'fre', 'lør', 'søn'],
            }
            if (!tempObj.datasets[1].data[6]?.y || tempObj.datasets[1].data[6].y == 0) {//Remove sunday if no hours
                tempObj.datasets[0].data.splice(6)
                tempObj.datasets[1].data.splice(6)
                tempObj.labels.splice(6)
            }
            if (!tempObj.datasets[1].data[5]?.y || tempObj.datasets[1].data[5].y == 0) {//Remove saturday if no hours
                tempObj.datasets[0].data.splice(5)
                tempObj.datasets[1].data.splice(5)
                tempObj.labels.splice(5)
            }
            // console.log(tempObj)
            return tempObj
        },
        totalMonthChartData() {
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.newIllnessStatsByMonth.map(month => month.illnessHoursPercentage).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.newIllnessStatsByMonth.map(month => month.illnessHours).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: this.newIllnessStatsByMonth.map(month => month.label),
            }
            return tempObj
        },
        totalDepartmentChartData() {
            // if (!this.myIllnessDataReady) return {datasets: [], labels: []}
            this.newIllnessStatsByMonth //Force update as data comes in
            let labels = Object.keys(this.newIllnessStatsTotal.byDepartment)
            let hours = Object.values(this.newIllnessStatsTotal.byDepartment).map(department => department.totalIllnessHours)
            let percentages = Object.values(this.newIllnessStatsTotal.byDepartment).map(department => department.illnessHoursPercentage)
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: percentages.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: hours.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: labels,
            }
            return tempObj
        },
        illnessStatsGroupedWithFlags() {
            let output = this.cloneJson(this.newIllnessStatsGrouped)

            for (let fitterInitials in this.handledIllness) {
                if (output[fitterInitials]) {
                    output[fitterInitials].illnessPeriods.forEach(illnessPeriod => {
                        let allPeriodIllnessesHandled = true
                        let anyPeriodIllnessesHandled = false
                        illnessPeriod.illnesses.forEach(illness => {
                            if (this.handledIllness[fitterInitials].includes(illness.ID)) {
                                illness.illnessHandled = true
                                anyPeriodIllnessesHandled = true
                            } else if (!illness.illnessHandled) {
                                allPeriodIllnessesHandled = false
                            }
                        })
                        illnessPeriod.illnessPeriodHandled = allPeriodIllnessesHandled
                        illnessPeriod.illnessPeriodPartiallyHandled = anyPeriodIllnessesHandled && !allPeriodIllnessesHandled

                        if (allPeriodIllnessesHandled) {
                            output[fitterInitials].handledIllnessPeriodsCount++
                            output[fitterInitials].nonHandledIllnessPeriodsCount = output[fitterInitials].illnessPeriods.length - output[fitterInitials].handledIllnessPeriodsCount
                        }
                    })
                }
            }

            return output
        },
        handledIllness() {
            let output = {}
            this.handledIllnessPeriods.forEach(period => {
                if (!output[period.fitterInitials]) {
                    output[period.fitterInitials] = period.illnessIds
                } else {
                    output[period.fitterInitials] = output[period.fitterInitials].concat(period.illnessIds)
                }
            })
            return output
        },
        handledIllnessPeriodsByFitter() {
            let output = {}
            this.handledIllnessPeriods.forEach(period => {
                if (!output[period.fitterInitials]) {
                    output[period.fitterInitials] = [period]
                } else {
                    output[period.fitterInitials].push(period)
                }
            })
            return output
        },
    },

    async mounted() {
        EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
        EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})
        this.selectDateRange(this.settingsIL.get('illnessTimeInterval'))

        this.$bind('handledIllnessPeriods', db.collection('HandledIllnessPeriods').where('lastIllnessDate', '>=', this.formatMachineDate(this.fromDate)))

        let rawFitterCategories = await this.getFitterCategories();
        let fitterCategoriesOptions = [];
        for (let category of rawFitterCategories) {
            fitterCategoriesOptions.push({value: category.ID, text: category.Display})
        }

        let illnessFitterHourCategoriesOptions = [];
        for (let category of rawFitterCategories) {
            if (category.IncludeIllness){
                illnessFitterHourCategoriesOptions.push({value: category.ID, text: category.Display})
            }
        }

        this.settingsIL.setOptions('illnessNonBreakingHourCategories', fitterCategoriesOptions);
        this.settingsIL.setOptions('illnessFitterHourCategories', illnessFitterHourCategoriesOptions);

        EventBus.$on('edit-settings-updated', (payload) => {
            for (let i in payload){
                this.onSettingUpdate(payload[i])
            }
        });

    },
    beforeDestroy() {
        this.$unbind('handledIllnessPeriods')

        EventBus.$off('edit-settings-modal-closing')
        EventBus.$off('edit-settings-modal-open')
        EventBus.$off('edit-settings-updated')
    },
}
</script>
<style scoped>
    .clickable:hover {
        cursor: pointer;
    }
    .dangerRow {
        background-color: rgb(255 0 0 / 7%) !important;
    }
    .dangerRow:nth-child(even) {
        background-color: rgb(255 0 0 / 9%) !important;
    }
</style>