// import swal from "sweetalert"
// import TaskCode from './Enums/TaskCode'
// import EventBus from '../EventBus'
// import { SubtaskMixin } from './SubtaskMixin'
import { Mixin } from './mixin'
// import TaskType from "./Enums/TaskType"
// import TaskState from "./Enums/TaskState"
// import ProjectType from "./Enums/ProjectType"
import AppointmentType from "../Enums/AppointmentType.js"

export const SortingMixin = {

    mixins: [Mixin],

    // enums: {
    //     TaskCode
    // },

    data() {
        return {
            unbookedSortingOptProjTasks: [
                {text: 'Planlagt Start', value: 'plannedStart'},
                {text: 'Planlagt Slut', value: 'plannedEnd'},
                {text: 'Connection dato', value: 'connectionDate'},
                {text: 'Opgave senest opdateret', value: 'updated'},
                {text: 'ServiceOrder Oprettet', value: 'serviceOrder.createdAt'},
                {text: 'Skabsnavn', value: 'configurationItem.cabinet.name'},
                {text: 'Postnr', value: 'configurationItem.address.zipcode'},
                {text: 'Område', value: 'configurationItem.area.name'},
                {text: 'Projekt type', value: 'project.type.label'},
                {text: 'Udbyder', value: 'serviceOrder.serviceProvider.name'},
                {text: 'Produkt-opstartsdato', value: 'productDeliveryDate'},
                {text: 'Mærkater', value: 'tags'}
            ],
    
            unbookedSortingOptTroubleTickets: [
                {text: 'Prioritet', value: 'priority.label'},
                {text: 'Urgency', value: 'urgency.label'},
                {text: 'Impact', value: 'impact.label'},
                {text: 'Deadline', value: 'dueDate'},
                {text: 'Ticket Oprettet', value: 'createdAt'},
                {text: 'Planlagt Start', value: 'plannedStart'},
                {text: 'Planlagt Slut', value: 'plannedEnd'},
                {text: 'Skabsnavn', value: 'installation.cabinet.name'},
                {text: 'Postnr', value: 'installation.address.zipcode'},
                {text: 'Område', value: 'installation.area.name'},
                {text: 'Udbyder', value: 'serviceProvider.name'},
                {text: 'Mærkater', value: 'tags'}
            ],

            instListSortingOptions: [
                {text: 'Alfabetisk', value: ''},
                {text: 'Planlagt Start', value: 'plannedStart'},
                {text: 'Planlagt Slut', value: 'plannedEnd'},
                {text: 'Connection dato', value: 'connectionDate'},
                {text: 'Opgave senest opdateret', value: 'updated'},
                {text: 'ServiceOrder Oprettet', value: 'serviceOrder.createdAt'},
                {text: 'Skabsnavn', value: 'configurationItem.cabinet.name'},
                {text: 'Postnr', value: 'configurationItem.address.zipcode'},
                {text: 'Område', value: 'configurationItem.area.name'},
                {text: 'Projekt type', value: 'project.type.label'},
                {text: 'Udbyder', value: 'serviceOrder.serviceProvider.name'},
            ],

            bookedSortingOptions: [
                {rank: 0, text: 'Initialer', value: 'workerInitials'},
                {rank: 1, text: 'Tidsrum', value: 'timeWindow'},
                {rank: 2, text: 'Postnummer', value: 'zipCode'},
                {rank: 3, text: 'Adresse', value: 'address'}
            ],

            unScheduledSortingOptions: [
                {rank: 0, text: 'DueDate', value: 'dueDate'},
                {rank: 1, text: 'Postnummer', value: 'zipCode'},
                {rank: 2, text: 'Adresse', value: 'address'}
            ],

        }
    },

    methods: {
        sortProjectTasks(t1, t2) {
            // PRJTASK0012357
            // const t1Task = t1.number.replace('PRJTASK', '')
            // const t2Task = t2.number.replace('PRJTASK', '')
            // return parseInt(t1Task) > parseInt(t2Task) ? 1 : -1 //ParseInt is only nessesary if task number is not a consistent length, but im 99% sure it is.
            return t1.number > t2.number ? 1 : -1
        },

        sortTroubleTickets(t1, t2) {
            // PRJTASK0012357
            const t1Task = t1.number.replace('TRT', '')
            const t2Task = t2.number.replace('TRT', '')

            return parseInt(t1Task) > parseInt(t2Task) ? 1 : -1
        },


        shouldFilteraAppointmentTypes(settings){
            if (!settings.get('showApptTypeTickets') || !settings.get('showApptTypeInspection') || !settings.get('showApptTypeInstallation') || !settings.get('showApptTypeTechnician') || !settings.get('showApptTypePatch') || !settings.get('showApptTypeKS')) return true
            return false
        },

        filterInsAppointmentTypes(settings, ins){
            let filterOut = true
            let appointmentType = this.getCurrentAppointmentType(ins)
            
            if (appointmentType == AppointmentType.TICKET && !settings.get('showApptTypeTickets')) filterOut = false
            if (appointmentType == AppointmentType.INSPECTION && !settings.get('showApptTypeInspection')) filterOut = false
            if (appointmentType == AppointmentType.INSTALLATION && !settings.get('showApptTypeInstallation')) filterOut = false
            if (appointmentType == AppointmentType.TECHNICIAN && !settings.get('showApptTypeTechnician')) filterOut = false
            if (appointmentType == AppointmentType.PATCH && !settings.get('showApptTypePatch')) filterOut = false
            if (appointmentType == AppointmentType.KSFIBER && !settings.get('showApptTypeKS')) filterOut = false

            return filterOut
        },

        nestedSortBookings(bookings) {
            for(let i in bookings){           
                let sortedBookings = this.sortBookingsBy(bookings[i],0)
                bookings[i] = sortedBookings
            }          
            return bookings
        },

        nestedSortInstallations(installations) {
            for(let i in installations){           
                let sortedBookings = this.sortBookingsBy(installations[i],0, true)
                installations[i] = sortedBookings
            }          
            return installations
        },

        sortBookingsBy(bookings, rank, noAppointments = false) {
            let sortingFunctions = []
            if (noAppointments){
                sortingFunctions = [
                    this.sortByDueDate,
                    this.sortByZipCode,
                    this.sortByAddress
                ]
            } else {
                sortingFunctions = [
                    this.sortByInitials,
                    this.sortByTimeWindow,
                    this.sortByZipCode,
                    this.sortByAddress
                ]
            }

            let compareValFunctions = []
            if (noAppointments){
                compareValFunctions = [
                    this.bookingGetDueDate,
                    this.bookingGetZipCode,
                    this.bookingGetAddress
                ]
            } else {
                compareValFunctions = [
                    this.bookingGetInitials,
                    this.bookingGetTimeWindow,
                    this.bookingGetZipCode,
                    this.bookingGetAddress
                ]
            }
            
            let index = 0
            if (noAppointments){index = this.unScheduledSortingOptions.findIndex(o => o.rank == rank)}
            else {index = this.bookedSortingOptions.findIndex(o => o.rank == rank)}
            let sortedBookings = sortingFunctions[index](bookings, noAppointments)

            // Return if sorted fully
            if (noAppointments && (index + 1 == this.unScheduledSortingOptions.length)) return sortedBookings
            else if (!noAppointments && (index + 1 == this.bookedSortingOptions.length)) return sortedBookings 

            // Call the next layer
            let results = []
            let splitData = this.splitBookings(sortedBookings, compareValFunctions[index], noAppointments)
            for (let i in splitData) {
                let tmpSorted = this.sortBookingsBy(splitData[i], rank+1, noAppointments)
                results.push(tmpSorted)
            }
            results = results.flat()
            return results
        },

        sortByInitials(bookings, noAppointments = false) {
            // Sort by worker initials in ascending alpfabetical order
            // Non assigned are sorted first 
            let sortedBookings = bookings.sort((a,b) => {
                    if (!a || !b) return 0

                    if (noAppointments){
                        if (!a.tasks[0]?.assignee || !b.tasks[0]?.assignee) return 0
                        a = a.tasks[0].assignee
                        b = b.tasks[0].assignee
                    } else {
                        a = a.appointment?.Worker?.Email?.split('@')[0].toLowerCase() || a.appointment?.WorkerInitials?.toLowerCase()
                        b = b.appointment?.Worker?.Email?.split('@')[0].toLowerCase() || b.appointment?.WorkerInitials?.toLowerCase()
                    }
                    if (a > b || (typeof b == 'undefined')) return this.sortAscending ? 1 : -1
                    if (a < b || (typeof a == 'undefined')) return this.sortAscending ? -1 : 1
                    return 0
                })
            return sortedBookings
        },

        sortByTimeWindow(bookings, noAppointments = false) {
            // Sorts by timeslot in ascending order
            // No timeslot are sorted first
            if (noAppointments) return bookings // Cannot sort by timeslot for unscheduled installations
            let sortedBookings = bookings.sort((a,b) => {
                if (!a || !b) return 0
                a = a.appointment?.TimeWindowString
                b = b.appointment?.TimeWindowString
                let aIsNaN = a?.split(';')[1].split(':')[0]
                let bIsNaN = b?.split(';')[1].split(':')[0]
                if (a > b || (bIsNaN == "NaN")) return this.sortAscending ? 1 : -1
                if (a < b || (aIsNaN == "NaN")) return this.sortAscending ? -1 : 1
                return 0
            })
            return sortedBookings
        },

        sortByZipCode(bookings, noAppointments = false) {
            // Sorts by zip code in ascending order
            let sortedBookings = bookings.sort((a,b) => {
                if (!a || !b) return 0

                if (noAppointments){
                    a = this.getConfiguration(a.tasks[0])?.address?.zipcode
                    b = this.getConfiguration(b.tasks[0])?.address?.zipcode
                } else {
                    if (a.appointment?.AppointmentType == "Custom") return -1 
                    if (b.appointment?.AppointmentType == "Custom") return 1
                    else {
                        if (!a.appointment?.AddressLong || b.appointment?.AddressLong) return 0
                        a = a.appointment?.AddressLong.split(',')[1].replace(/\D/g,'')
                        b = b.appointment?.AddressLong.split(',')[1].replace(/\D/g,'')
                    }  
                }
                if (a > b) return this.sortAscending ? 1 : -1
                if (a < b) return this.sortAscending ? -1 : 1
                return 0
            })
            return sortedBookings
        },

        sortByAddress(bookings, noAppointments = false) {
            // Sort by address in ascending alpfabetical order
            let sortedBookings = bookings.sort((a,b) => {
                if (!a || !b) return 0

                let addressA = ''
                let addressB = ''
                let houseNumbA = ''
                let houseNumbB = ''
                let letterA = ''
                let letterB = ''
                let appartmentA = ''
                let appartmentB = ''
                if (noAppointments){
                    addressA = this.getConfiguration(a.tasks[0])?.address?.road
                    addressB = this.getConfiguration(b.tasks[0])?.address?.road
                    houseNumbA = parseInt(this.getConfiguration(a.tasks[0])?.address?.number)
                    houseNumbB = parseInt(this.getConfiguration(b.tasks[0])?.address?.number)
                    letterA = this.getConfiguration(a.tasks[0])?.address?.letter
                    letterB = this.getConfiguration(b.tasks[0])?.address?.letter
                    appartmentA = parseInt(this.getConfiguration(a.tasks[0])?.address?.appartment)
                    appartmentB = parseInt(this.getConfiguration(b.tasks[0])?.address?.appartment)
                } else {
                    if (a.appointment?.AppointmentType == "Custom") return -1 
                    if (b.appointment?.AppointmentType == "Custom") return 1
                    else {  
                        if (a.appointment?.AddressLong && b.appointment?.AddressLong) {
                            addressA = a.appointment?.AddressLong.split(',')[0].split(' ')[0]
                            addressB = b.appointment?.AddressLong.split(',')[0].split(' ')[0]
                            houseNumbA = parseInt(a.appointment?.AddressLong?.split(',')[0].split(' ')[1])
                            houseNumbB = parseInt(b.appointment?.AddressLong?.split(',')[0].split(' ')[1]) 
                            // TODO: Include letter and appartment for appointment aswell     
                        }
                    }
                }
                if (addressA > addressB) return this.sortAscending ? 1 : -1
                else if (addressA < addressB) return this.sortAscending ? -1 : 1
                else { // addressA == addressB
                    if (houseNumbA > houseNumbB) return this.sortAscending ? 1 : -1
                    else if (houseNumbA < houseNumbB) return this.sortAscending ? -1 : 1
                    else { // houseNumbA == houseNumbB
                        if (letterA > letterB) return this.sortAscending ? 1 : -1
                        else if (letterA < letterB) return this.sortAscending ? -1 : 1
                        else { // letterA == letterB
                            if (appartmentA > appartmentB) return this.sortAscending ? 1 : -1
                            if (appartmentA < appartmentB) return this.sortAscending ? -1 : 1
                        }
                    }

                }
                return 0
            })
            return sortedBookings
        },

        sortByDueDate(bookings, noAppointments = false){
            let sortedBookings = bookings.sort((a,b) => {
                if (!a || !b) return 0

                if (noAppointments){
                    if (!a.troubleTicketCount && !b.troubleTicketCount) return 0
                    if (a.tasks[0].dueDate > b.tasks[0].dueDate) return this.sortAscending ? 1 : -1
                    if (b.tasks[0].dueDate > a.tasks[0].dueDate) return this.sortAscending ? -1 : 1
                }
                return 0
            })
            return sortedBookings
        },

        splitBookings(bookings, compareValFunc, noAppointments = false) {
            let data = []
            let splitData = []
            splitData.push(bookings[0])
            let currCompare = compareValFunc(bookings[0], noAppointments)
            let prevCompare = currCompare

            for (let i = 1; i < bookings.length; i++) {
                currCompare = compareValFunc(bookings[i], noAppointments)
                if (currCompare == prevCompare) {
                    splitData.push(bookings[i])
                    prevCompare = currCompare
                } else {
                    data.push(splitData)
                    splitData = []
                    splitData.push(bookings[i])
                    prevCompare = currCompare
                }
            }
            data.push(splitData)
            return data
        },

    },
}
