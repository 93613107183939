<template>
    <div class="illnessStatsFitter" :id="`illnessStatsFitter-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`">
        <IllnessPeriodModal v-if="selectedPeriod" :isOpen="illnessPeriodModalOpen" @close="illnessPeriodModalOpen=false" :illnessPeriod="selectedPeriod" :fitterName="fitterIllness?.fitterName" :fitterInitials="fitterIllness?.fitterSalaryID" :handledFitterIllness="handledFitterIllness" @deepclose="deepClose()" />
        <div class="row">
            <div class="col-sm-4">
                <Bar
                    :chart-id="`chart-illnessHoursByWeekday-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`" 
                    :chart-data="fitterIllnessByWeekday" 
                    :chart-options="illnessChartOptions"
                />
            </div>
            <div class="col-sm-4">
                <Bar
                    :chart-id="`chart-illnessHoursByWeekday-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`" 
                    :chart-data="fitterIllnessByMonth" 
                    :chart-options="illnessChartOptions"
                />
            </div>
            <div class="col-sm-4" v-if="fitterIllness">
                <sui-statistics-group horizontal>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalIllnessHours }}</sui-statistic-value>
                        <sui-statistic-label>Sygetimer</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="typeof fitterIllness.illnessHoursPercentage  == 'number'">
                        <sui-statistic-value>{{ fitterIllness.illnessHoursPercentage.toFixed(1) }} %</sui-statistic-value>
                        <sui-statistic-label>Sygetimer</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalIllnessDays }}</sui-statistic-value>
                        <sui-statistic-label>Syge dage</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="typeof fitterIllness.illnessDaysPercentage == 'number'" >
                        <sui-statistic-value>{{ fitterIllness.illnessDaysPercentage.toFixed(1) }} %</sui-statistic-value>
                        <sui-statistic-label>Syge dage</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="fitterIllness.illnessPeriods && typeof fitterIllness.nonHandledIllnessPeriodsCount == 'number'">
                        <sui-statistic-value>{{ fitterIllness.nonHandledIllnessPeriodsCount }}</sui-statistic-value>
                        <sui-statistic-label>Uhåndtere{{ fitterIllness.nonHandledIllnessPeriodsCount != 1 ? 'de' : 't' }} sygdoms periode{{ fitterIllness.nonHandledIllnessPeriodsCount != 1 ? 'r' : '' }}</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="fitterIllness.illnessPeriods && typeof fitterIllness.illnessPeriods.length  == 'number'">
                        <sui-statistic-value>{{ fitterIllness.illnessPeriods.length }}</sui-statistic-value>
                        <sui-statistic-label>Sygdoms periode{{ fitterIllness.illnessPeriods.length != 1 ? 'r' : '' }} i alt</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalHours }}</sui-statistic-value>
                        <sui-statistic-label>Timer totalt</sui-statistic-label>
                    </sui-statistic>
                </sui-statistics-group>
            </div>
        </div>
        <h2 v-if="fitterIllness && fitterIllness.illnessPeriods && fitterIllness.illnessPeriods.length">Sygdoms-perioder</h2>
        <sui-table striped selectable v-if="fitterIllness && fitterIllness.illnessPeriods && fitterIllness.illnessPeriods.length">
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell><i class="fas fa-check" title="Sygeperioden er markeret som 'håndteret'" style="color: green;"></i></sui-table-header-cell>
                    <sui-table-header-cell>Første sygedag</sui-table-header-cell>
                    <sui-table-header-cell>Sidste sygedag</sui-table-header-cell>
                    <sui-table-header-cell>Antal dage</sui-table-header-cell>
                    <sui-table-header-cell>Antal timer</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <sui-table-row v-for="illnessPeriod of fitterIllness.illnessPeriods" :key="illnessPeriod.precededByDate" class="clickable" @click="selectIllnessPeriod(illnessPeriod)">
                    <sui-table-cell>
                        <i v-if="illnessPeriod.illnessPeriodHandled" class="fas fa-check" title="Sygeperioden er markeret som 'håndteret'" style="color: green;"></i>
                        <i v-else-if="illnessPeriod.illnessPeriodPartiallyHandled" class="fa-solid fa-triangle-exclamation" title="Sygeperioden er delvist markeret som 'håndteret'" style="color: orange;"></i>
                    </sui-table-cell>
                    <sui-table-cell>{{ toUserFriendlyDate(illnessPeriod.firstIllnessDate) }}</sui-table-cell>
                    <sui-table-cell>{{ toUserFriendlyDate(illnessPeriod.lastIllnessDate) }}</sui-table-cell>
                    <sui-table-cell>{{ illnessPeriod.illnessDates.length }}</sui-table-cell>
                    <sui-table-cell>{{ illnessPeriod.totalIllnessHours }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
        <!-- <sui-table>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell>Dato</sui-table-header-cell>
                    <sui-table-header-cell>Timer</sui-table-header-cell>
                    <sui-table-header-cell>Løndel</sui-table-header-cell>
                    <sui-table-header-cell>Sag</sui-table-header-cell>
                    <sui-table-header-cell>Evt note</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <sui-table-row v-for="illness of fitterIllness" :key="illness.id">
                    <sui-table-cell>{{ toUserFriendlyDate(illness.Date) }}</sui-table-cell>
                    <sui-table-cell>{{ illness.Hours }}</sui-table-cell>
                    <sui-table-cell>{{ illness.CategoryName }}</sui-table-cell>
                    <sui-table-cell>{{ illness.ProjectDescription }}</sui-table-cell>
                    <sui-table-cell>{{ illness.Note }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table> -->
    </div>
</template>
<script>
import { DateMixin } from '../../lib/Mixins/dateMixin'
// import PureVueChart from 'pure-vue-chart';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'
import IllnessPeriodModal from './IllnessPeriodModal.vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'illnessStatsFitter',
    components: {
        Bar,
        IllnessPeriodModal,
    },
    mixins: [DateMixin],

    props: {
        fitterIllness: Object,
        handledFitterIllness: Array,
        dataIsReady: Boolean,
    },

    data(){
        return {
            illnessChartOptions: {
                responsive: true,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            },
            selectedPeriod: null,
            illnessPeriodModalOpen: false,
        }
    },

    computed: {
        fitterIllnessByWeekday() {
            if (!this.fitterIllness?.illnessHoursByWeekday || !this.fitterIllness?.illnessHoursPercentageByWeekday) return {datasets: [], labels: []}
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.fitterIllness.illnessHoursPercentageByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.fitterIllness.illnessHoursByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: ['man', 'tirs', 'ons', 'tors', 'fre', 'lør', 'søn'],
            }
            if (!tempObj.datasets[1].data[6]?.y || tempObj.datasets[1].data[6].y == 0) {//Remove sunday if no hours
                tempObj.datasets[0].data.splice(6)
                tempObj.datasets[1].data.splice(6)
                tempObj.labels.splice(6)
            }
            if (!tempObj.datasets[1].data[5]?.y || tempObj.datasets[1].data[5].y == 0) {//Remove saturday if no hours
                tempObj.datasets[0].data.splice(5)
                tempObj.datasets[1].data.splice(5)
                tempObj.labels.splice(5)
            }
            // console.log(tempObj)
            return tempObj
        },
        fitterIllnessByMonth() {
            if (typeof this.fitterIllness?.statsByMonth?.[0]?.illnessHoursPercentage == 'undefined' && typeof this.fitterIllness?.statsByMonth?.[0]?.illnessHours == 'undefined') return {datasets: [], labels: []}
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.fitterIllness.statsByMonth.map(month => month.illnessHoursPercentage).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.fitterIllness.statsByMonth.map(month => month.illnessHours).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: this.fitterIllness.statsByMonth.map(month => month.label),
            }
            return tempObj
        },
        containerWidth() {
            return document?.getElementById(`illnessStatsFitter-${this.fitterIllness ? this.fitterIllness.fitterSalaryID : 'undefined'}`)?.offsetWidth || window.innerWidth*0.8
        },
        graphWidth() {
            // if (!this.dataIsReady) return 300
            console.log(document.getElementsByClassName('col-sm-4')[0]?.offsetWidth - 50)
            return document.getElementsByClassName('col-sm-4')[0]?.offsetWidth - 50 || 300
            // return (this.containerWidth / 3) - 20
        },
    },

    methods: {
        selectIllnessPeriod(illnessPeriod) {
            // this.$emit('selectIllnessPeriod', illnessPeriod)
            this.selectedPeriod = illnessPeriod
            this.illnessPeriodModalOpen = true
            console.log(illnessPeriod)
        },
        deepClose() {
            this.illnessPeriodModalOpen = false
            this.$emit('deepclose')
        },
    },
}
</script>
<style scoped>
    .graph-col {
        width: 100%;
    }
    .clickable:hover {
        cursor: pointer;
    }
</style>