<template>
    <div>
        <sui-modal
            :size="modalSize"
            id="editImageModal"
            :open="isOpen"
        >
            <sui-modal-header>
                {{ modalHeader }}
                <sui-button
                    class="square-icon-button modal-close-button"
                    :class="currentMedia.type === 'Mobile' ? 'mobile' : ''"
                    size="mini"
                    title="Annullér"
                    floated="right"
                    @click="clickAway()"
                ><i class="fa-solid fa-xmark"></i></sui-button>
                <sui-button
                    class="square-icon-button modal-close-button"
                    v-if="currentMedia.type === 'Desktop' && modalSize == 'small'"
                    size="mini"
                    title="Forstør"
                    floated="right"
                    @click="enlargeModal()"
                ><i class="fa-solid fa-up-right-and-down-left-from-center"></i></sui-button>
                <sui-button
                    class="square-icon-button modal-close-button"
                    v-if="currentMedia.type === 'Desktop' && modalSize == 'large'"
                    size="mini"
                    title="Formindsk"
                    floated="right"
                    @click="reduceModal()"
                ><i class="fa-solid fa-down-left-and-up-right-to-center"></i></sui-button>
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;">
                <sui-form>
                    <!-- Canvas Section -->
                    <div class="canvas-container" :class="{'large': modalSize == 'large'}">
                        <canvas 
                            ref="canvas" 
                            class="check-image" 
                            :class="{'large': modalSize == 'large'}"
                            @mousedown="onMouseDown"
                            @mousemove="onMouseMove"
                            @mouseup="onMouseUp"
                            @touchstart="onMouseDown"
                            @touchmove="onMouseMove"
                            @touchend="onMouseUp"
                            @touchmove.prevent
                        ></canvas>
                    </div>

                    <!-- Message for missing image -->
                    <sui-message 
                        v-if="!imageFound && image"
                    >
                        <sui-message-header>Billedet blev ikke fundet</sui-message-header>
                        <p>
                            Prøv at uploade billedet igen eller vælg et andet billede
                        </p>
                    </sui-message>

                    <!-- File upload -->
                    <div class="button-slider" v-if="!showDrawTools"> 

                        <sui-button
                            size="mini"
                            class="actions-buttons"
                            title="Upload eller tag billede med telefonens kamera"
                            @click.prevent="triggerFileInput"
                            >
                            <i class="fa-solid fa-camera"></i>
                            Upload eller tag billede
                        </sui-button> 
                        <input ref="embedFileInput" type="file" hidden @change="setFileUpload"/>
                        
                        <sui-button
                            size="mini"
                            class="actions-buttons"
                            title="Vælg billede fra biblioteket af allerede uploadede billeder"
                            @click.prevent="openImageSelectorModal()"
                        >
                            <i class="fa-solid fa-images"></i>
                            Vælg fra bibliotek
                        </sui-button> 
                            
                        <sui-button
                            size="mini"
                            class="actions-buttons"
                            title="Rediger billedet"
                            @click.prevent="showDrawTools = true"
                        >
                            <i class="fa-solid fa-sliders"></i>
                            Rediger
                        </sui-button> 
                    </div>

                    <!-- Modify tools -->
                    <div class="button-slider" v-if="showDrawTools">
                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'select' }"
                            size="mini"
                            title="Vælg (S)"
                            :disabled="selectButtonDisabled"
                            @click.prevent="selectTool('select')"
                        >
                            <i class="fa-solid fa-arrow-pointer"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'move' }"
                            size="mini"
                            title="Flyt (M)"
                            :disabled="moveButtonDisabled"
                            @click.prevent="selectTool('move')"
                        >
                            <i class="fa-solid fa-up-down-left-right"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'resize' }"
                            size="mini"
                            title="Skalér (R)"
                            :disabled="resizeButtonDisabled"
                            @click.prevent="selectTool('resize')"
                        >
                            <i class="fa-solid fa-expand"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            title="Slet (Delete)"
                            :disabled="moveButtonDisabled"
                            @click.prevent="deleteSelectedObject()"
                        >
                            <i class="fa-solid fa-trash"></i>
                        </sui-button>

                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            title="Slet alle tegninger (Shift + Delete)"
                            :disabled="selectButtonDisabled"
                            @click.prevent="clearAllDraws()"
                        >
                            <i class="fa-solid fa-trash-list"></i>
                        </sui-button>

                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            title="Fortryd (Ctrl + Z)"
                            :disabled="undoButtonDisabled"
                            @click.prevent="undoDraw()"
                        >
                            <i class="fa-solid fa-arrow-turn-left"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            title="Annuller fortryd (Ctrl + Y)"
                            :disabled="redoButtonDisabled"
                            @click.prevent="redoDraw()"
                        >
                            <i class="fa-solid fa-arrow-turn-right"></i>
                        </sui-button> 
                    </div>

                    <!-- Draw tools -->
                    <div class="button-slider" v-if="showDrawTools" style="margin-top: -10px;">
                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'freehand', 'secondary': isSecondaryButton('freehand') }"
                            size="mini"
                            title="Frihåndstegning (P)"
                            @click.prevent="selectTool('freehand')"
                        >
                            <i class="fa-solid fa-pen-swirl"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'line', 'secondary': isSecondaryButton('line') }"
                            size="mini"
                            title="Linje (L)"
                            @click.prevent="selectTool('line')"
                        >
                            <i class="fa-solid fa-pen-line"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'textbox', 'secondary': isSecondaryButton('textbox') }"
                            size="mini"
                            title="Tekstboks (T)"
                            @click.prevent="selectTool('textbox')"
                        >
                            <i class="fa-solid fa-text"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'x', 'secondary': isSecondaryButton('x') }"
                            size="mini"
                            title="Indsæt kryds (X)"
                            @click.prevent="selectTool('x')"
                        >
                            <i class="fa-solid fa-xmark-large"></i>
                        </sui-button> 

                        <sui-button
                            class="square-icon-button fa-2xl"
                            :class="{ 'primary': drawMode == 'o', 'secondary': isSecondaryButton('o') }"
                            size="mini"
                            title="Indsæt cirkel (O)"
                            @click.prevent="selectTool('o')"
                        >
                            <i class="fa-solid fa-o"></i>
                        </sui-button> 
                    </div>

                    <!-- Tool options -->
                    <div v-if="showPenToolOptions || showXandOToolOptions || showTextBoxToolOptions" style="margin-top: 5px;">
                        <i 
                            v-for="(color, name) in colorOptions" 
                            :key="name" 
                            class="fa-duotone fa-regular fa-square" 
                            :class="drawColor == color ? 'fa-2xl' : 'fa-xl'"
                            style="--fa-primary-opacity: 1; --fa-secondary-opacity: 1;"
                            :style="`--fa-secondary-color: ${ color };`"
                            @click="drawColor = color"
                            :title="colorTitles[name]"
                        ></i>
                    </div>
                    <div v-if="showTextBoxToolOptions" style="margin-top: 10px;">
                        <span v-for="(color, name) in fillColorOptions" :key="name">
                            <i 
                                v-if="name != 'transparent'"
                                class="fa-duotone fa-regular fa-square" 
                                :class="textBoxBackground == color ? 'fa-2xl' : 'fa-xl'"
                                style="--fa-primary-opacity: 1; --fa-secondary-opacity: 1;"
                                :style="`--fa-secondary-color: ${ color };`"
                                @click="textBoxBackground = color"
                                :title="fillColorTitles[name]"
                            ></i>
                            <i 
                                v-if="name == 'transparent'"
                                class="fa-duotone fa-solid fa-chess-board"
                                :class="textBoxBackground == color ? 'fa-2xl' : 'fa-xl'"
                                style="--fa-primary-opacity: 1; --fa-secondary-opacity: 1; --fa-primary-color: grey; --fa-secondary-color: black;"
                                @click="textBoxBackground = color"
                                :title="fillColorTitles[name]"
                            ></i>
                        </span>
                    </div>
                    <div v-if="showPenToolOptions || showXandOToolOptions" style="margin-top: 7px;"> 
                        <span>Stregtykkelse: {{ lineWidth }}</span><br>
                        <input type="range" v-model="lineWidth" min="1" max="100" />
                    </div>
                    <div v-if="showXandOToolOptions" style="margin-top: 7px;"> 
                        <span>Størrelse: {{ size }}</span><br>
                        <input type="range" v-model="size" min="1" max="400" />
                    </div>
                    <div v-if="showTextBoxToolOptions" style="margin-top: 7px;"> 
                        <span>Skriftstørrelse: {{ fontSize }}</span><br>
                        <input type="range" v-model="fontSize" min="1" max="100" />
                    </div>
                    <div class="form-field-spacing" v-if="showTextBoxToolOptions">
                        <sui-form-field>
                            <label> Tekstboks </label>
                            <textarea 
                                placeholder="" 
                                rows="3"
                                v-model="textBoxContent" 
                                @focus="isTyping = true" 
                                @blur="isTyping = false"
                            ></textarea>
                        </sui-form-field>
                    </div>
                    <div class="button-slider" v-if="showDrawTools && drawMode == 'resize' && selectedObject.type == 'freehand'" style="margin-top: -10px;">
                        <sui-button
                            class="square-icon-button fa-2xl"
                            size="mini"
                            :title="keepAspectRatio ? 'Slå bevar aspektforhold fra' : 'Slå bevar aspektforhold til'"
                            @click.prevent="keepAspectRatio = !keepAspectRatio"
                        >
                            <i v-if="keepAspectRatio" class="fa-solid fa-link"></i>
                            <i v-else class="fa-solid fa-link-slash"></i>
                        </sui-button> 
                    </div>

                    <!-- Form options -->
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <label> Hvordan skal billedet vises i PDF'en? </label>
                            <sui-dropdown
                                selection
                                search
                                :options="renderingOptions"
                                v-model="renderingOption"
                            ></sui-dropdown>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <sui-checkbox 
                                label="Inkluder billede i PDF" 
                                v-model="includeInPdf"
                            ></sui-checkbox>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <label> Billedtekst </label>
                            <textarea 
                                placeholder="Beskriv billedet" 
                                v-model="imageText" 
                            ></textarea>
                        </sui-form-field>
                    </div>
                                   

                </sui-form>
            </sui-modal-content>

            <sui-modal-actions>
                <sui-button
                    class="square-icon-button"
                    positive 
                    size="mini"
                    title="Gem billedet"
                    @click.prevent="onSave()"
                ><i class="fa-solid fa-floppy-disk"></i></sui-button>

                <sui-button
                    class="square-icon-button"
                    negative
                    size="mini"
                    title="Slet billedet"
                    @click.prevent="onDelete()"
                    :disabled="deleteButtonDisabled"
                ><i class="fa-solid fa-trash"></i></sui-button>
            </sui-modal-actions>

        </sui-modal>
       
        <image-selector-modal
            :isOpen="imageSelectorModalOpen"
            :baseFilePath="imageSelectorBaseFilePath"
            @select-image="selectImage"
            @close="closeImageSelectorModal"
        />
    </div>                             
</template>

<script>
import EventBus from '@/EventBus'
import swal from 'sweetalert'

import { storageRef } from '@/firebase'
import { JSON2HTML } from '@/modules/FormToPdf/mixins/JSON2HTMLMixin.js'
import { Mime } from '@/lib/helpers/mime.js'
import ImageSelectorModal from '@/components/Images/ImageSelectorModal.vue'
    
export default {

        mixins: [
            // Mixin,
            Mime,
            EventBus,
            JSON2HTML,
        ],

        components: {
            ImageSelectorModal
        },

        props: {
            isOpen: Boolean,
            form: Object,
            image: Object,
            indices: Object,
            path: String,
        },

        data() {
            return {
                modalSize: 'small',

                imagePath: '',
                imageURL: '',
                imageText: '',
                imageFound: false,
                renderingOption: 'largeImage',
                includeInPdf: true,

                renderingOptions: [
                    { text: 'Stort billede (Billedtekst under)', value: 'largeImage' },
                    { text: 'Lille billede (Billedteskt til højre for)', value: 'smallImage' },
                    { text: 'Stort billede uden billedtekst', value: 'largeImageWithoutText' },
                ],

                // File upload
                fileFormSuccess: false,
                showProgress: false,
                fileUpload: null,

                // Image selector
                imageSelectorModalOpen: false,

                canvasImage: null,
                drawMode: null,
                drawColor: '#000000',
                lineWidth: 5,
                size: 20,
                fontSize: 20,
                history: [],
                redoStack: [],
                textBoxBackground: '#FFFFFF',
                textBoxContent: 'Tekstboks\n - Skriv her',  
                isTyping: false,
                isDrawing: false,
                isDragging: false,
                isSelecting: false,
                isResizing: false,
                resizeHandle: null,
                movePoint: null,
                keepAspectRatio: true,

                offsetX: 0,
                offsetY: 0,
                scaleX: 1,
                scaleY: 1,
                handleSize: 20,
                currentPath: [],
                textBoxEndPoint: { x: 0, y: 0 },
                selectedIndex: -1,
                selectedObject: null,
                selectionBox: null,
                dragStart: { x: 0, y: 0 }, // Mouse position when dragging starts
                initialMousePos: { x: 0, y: 0 }, // Initial mouse position when resizing
                initialBoundingBox: { x: 0, y: 0, width: 0, height: 0 }, // Initial bounding box when resizing
                initialPath: [], // Initial path when resizing

                showDrawTools: false,

                colorOptions: {
                    'black': '#000000',
                    'white': '#FFFFFF',
                    'red': '#FF0000',
                    'orange': '#FFA500',
                    'yellow': '#FFFF00',
                    'green': '#008000',
                    'blue': '#0000FF',
                    'purple': '#800080',
                },
                colorTitles: {
                    'black': 'Sort (1)',
                    'white': 'Hvid (2)',
                    'red': 'Rød (3)',
                    'orange': 'Orange (4)',
                    'yellow': 'Gul (5)',
                    'green': 'Grøn (6)',
                    'blue': 'Blå (7)',
                    'purple': 'Lilla (8)',
                },

                fillColorOptions: {
                    'transparent': 'transparent',
                    'black': '#000000',
                    'white': '#FFFFFF',
                },
                fillColorTitles: {
                    'transparent': 'Gennemsigtig',
                    'black': 'Sort',
                    'white': 'Hvid',
                },
            }
        },

        computed: {
            user() {
                return this.$root.$children[0].user
            },

            currentMedia(){
                return this.$root.$children[0].currentMedia
            },

            modalHeader(){
                if (this.image) {
                    return 'Rediger Billede'
                }
                return 'Tilføj Billede'
            },

            deleteButtonDisabled(){
                if (this.image) {
                    return false
                }

                if (this.imagePath || this.imageURL) {
                    return false
                }

                return true
            },

            imageSelectorBaseFilePath(){
                return `${this.path}/Images/`
            },

            showPenToolOptions(){
                if (!this.showDrawTools) return false;
                if (this.drawMode == 'freehand') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'freehand') return true;

                if (this.drawMode == 'line') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'line') return true;

                return false;
            },

            showXandOToolOptions(){
                if (!this.showDrawTools) return false;
                if (this.drawMode == 'x') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'x') return true;

                if (this.drawMode == 'o') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'o') return true;

                return false;
            },

            showTextBoxToolOptions(){
                if (!this.showDrawTools) return false;
                if (this.drawMode == 'textbox') return true;
                if (this.drawMode == 'select' && this.selectedObject && this.selectedObject.type == 'textbox') return true;

                return false;
            },

            selectButtonDisabled(){
                return this.history.length === 0;
            },

            undoButtonDisabled(){
                return this.history.length === 0;
            },

            redoButtonDisabled(){
                return this.redoStack.length === 0;
            },

            moveButtonDisabled(){
                return this.selectedObject === null;
            },

            resizeButtonDisabled(){
                if (this.selectedObject == null) return true;
                if (this.selectedObject?.type === 'textbox') return true;
                if (this.selectedObject?.type === 'x') return true;
                if (this.selectedObject?.type === 'o') return true;

                return false;
            }
        },

        methods: {
            enlargeModal(){
                this.modalSize = 'large';
            },

            reduceModal(){
                this.modalSize = 'small';
            },

            clickAway() {
                this.modalSize = 'small';

                this.imageSelectorModalOpen = false;

                this.imagePath = '';
                this.imageURL = '';
                this.imageText = '';
                this.imageFound = false;
                this.renderingOption = 'largeImage';
                this.includeInPdf = true;
                this.fileUpload = null;

                this.showDrawTools = false;
                this.history = [];
                this.redoStack = [];
                this.textBoxBackground = '#FFFFFF';
                this.textBoxContent = 'Tekstboks\n - Skriv her';
                this.selectedObject = null;
                this.selectionBox = null;
                this.isDrawing = false;
                this.isDragging = false;
                this.isSelecting = false;
                this.isResizing = false;
                this.keepAspectRatio = true;
                this.resizeHandle = null;
                this.movePoint = null;
                this.offsetX = 0;
                this.offsetY = 0;
                this.scaleX = 1;
                this.scaleY = 1;
                this.handleSize = 20;
                this.currentPath = [];


                let queryImage = document.querySelector('.check-image');
                queryImage.removeEventListener('error', this.imageNotFoundHandler);

                EventBus.$emit('event-edit-image-click-away');
            },

            triggerFileInput(){
                this.$refs.embedFileInput.click()
            },

            async setFileUpload(e){
                this.fileFormSuccess = false
                this.showProgress = false
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    this.fileUpload = null
                    return false
                }
                this.fileUpload = files[0]
                this.imageURL = await this.encodeFile(this.fileUpload)
                // console.log("file", base64Content)
                return true
            },

            async onSave(){
                console.log('saving')
                
                EventBus.$emit('function-activity', { functionName: 'ImageEditorModal_onSave', isActive: true })

                if (this.fileUpload) {

                    // Upload image to storage
                    let fileName = `IMG_${new Date().toISOString()}`
                    
                    const expectedExtension = this.getExtenstionByMimeType(this.fileUpload.type)
                    const fileExtension = this.getFileExtension(fileName)
                    if (fileExtension.toLowerCase() != expectedExtension) {
                        fileName += `.${expectedExtension}`
                    }

                    let metadata = {
                        contentType: this.fileUpload.type,
                        customMetadata: { //Custom metadata for uploads to Firebase storage MUST be a simple object with no nesting
                            'uploadedBy': this.user.email
                        }
                    }

                    let base64Content = this.imageURL
                    base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)


                    let imageFilePath = `${this.path}/Images/${fileName}`
                    console.log("image file path", imageFilePath)
                    let newFileRef = storageRef.child(imageFilePath)
                    console.log("new file ref", newFileRef)
                    console.log("base64Content", base64Content);

                    let scaledImage = await this.scaleBase64Image(base64Content, 3000, 3000, expectedExtension);
                    console.log("scaledImage", scaledImage);
                    await newFileRef.putString(scaledImage, 'base64', metadata)

                    this.imagePath = imageFilePath
                    if (this.image){
                        this.imageURL = await this.getImageURL(this.imagePath)
                    }
                    this.imageFound = true
                    
                    console.log("new image to upload")
                }

                let compressedHistory = [];
                if (this.history.length > 0) {
                    compressedHistory = this.compressHistory();
                }

                let imageData = {
                    imagePath: this.imagePath,
                    imageURL: this.imageURL,
                    imageText: this.imageText,
                    imageFound: this.imageFound,
                    renderingOption: this.renderingOption,
                    includeInPdf: this.includeInPdf,
                    canvasHistory: compressedHistory,
                }

                if (this.image) {
                    EventBus.$emit('event-image-editor-update', { imageData, indices: this.indices })
                    console.log('update-image', imageData)
                } else {
                    EventBus.$emit('event-image-editor-add', { imageData, indices: this.indices })
                    console.log('add-image', imageData)
                }
                this.clickAway()

                EventBus.$emit('function-activity', { functionName: 'ImageEditorModal_onSave', isActive: false })
            },

            async onDelete(){
                let shouldDelete = await swal({
                    title: "Er du sikker?",
                    text: "Billedet vil blive slettet permanent",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })

                if (!shouldDelete) {
                    return
                }
                EventBus.$emit('event-image-editor-delete', { indices: this.indices })
                this.clickAway()
            },

            async prePopulateForm(){
                console.log("prePopulateForm")
                console.log("Image: ", this.image)
                console.log("Indices: ", this.indices)

                if (this.image) {
                    this.imagePath = this.image.imagePath
                    this.imageURL = this.image.imageURL
                    this.imageText = this.image.imageText
                    this.imageFound = this.image.imageFound
                    this.renderingOption = this.image.renderingOption
                    this.includeInPdf = this.image.includeInPdf
                    this.history = this.image.canvasHistory || []
                } else {
                    this.imagePath = 'FormToPdfAssets/Placeholder_view_vector.jpg'
                    this.imageURL = await this.getImageURL(this.imagePath)
                }

                this.checkImages();
                // this.loadImage(this.imageURL);
            },

            async encodeFile(file){
                EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: true})
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve (reader.result)
                    reader.onerror = error => reject(error)
                })
                let encodedFile = await toBase64(file).catch(e => Error(e))
                if(encodedFile instanceof Error) {
                    console.log('Error encoding file: ',encodedFile.message)
                    EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: false})
                    return
                }
                EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: false})
                return encodedFile
            },

            openImageSelectorModal(){
                this.imageSelectorModalOpen = true
            },

            closeImageSelectorModal(){
                this.imageSelectorModalOpen = false
            },

            async selectImage(imageRef){
                this.imageURL = await imageRef.getDownloadURL()
                this.imagePath = imageRef.fullPath
            },

            async imageNotFoundHandler(){
                console.log("Image not found", this.imagePath);
                try {
                    this.imageURL = await this.getImageURL(this.imagePath);
                } catch {
                    console.log("Image not found", this.imagePath);
                    this.imageFound = false;
                }
            },

            checkImages(){
                let queryImage = document.querySelector('.check-image');
                queryImage.addEventListener('error', this.imageNotFoundHandler);
            },

            scaleBase64Image(base64Image, maxWidth, maxHeight, mimeType) {
                return new Promise((resolve, reject) => {
                    
                    // Validate base64 format
                    const base64Regex = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/i;
                    if (!base64Regex.test(base64Image)) {
                        base64Image = `data:image/${mimeType};base64,${base64Image}`;

                        // return reject(new Error("Invalid base64 image format."));
                    }

                    // Create an image element
                    const img = new Image();

                    // Set the source of the image to the base64 data
                    img.src = base64Image;

                    img.onload = () => {
                        // Get the original dimensions of the image
                        const originalWidth = img.width;
                        const originalHeight = img.height;

                        // Check if the image is already smaller than the maximum dimensions
                        if (originalWidth <= maxWidth && originalHeight <= maxHeight) {
                            // Resolve the promise with the original base64 image
                            resolve(base64Image);
                            return;
                        }

                        // Calculate the scaling factor
                        const widthRatio = maxWidth / originalWidth;
                        const heightRatio = maxHeight / originalHeight;
                        const scalingFactor = Math.min(widthRatio, heightRatio, 1); // Ensure we don't upscale

                        // Compute the new dimensions
                        const newWidth = Math.floor(originalWidth * scalingFactor);
                        const newHeight = Math.floor(originalHeight * scalingFactor);

                        // Create a canvas to resize the image
                        const canvas = document.createElement('canvas');
                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        const ctx = canvas.getContext('2d');

                        // Apply image smoothing options
                        ctx.imageSmoothingEnabled = true;
                        ctx.imageSmoothingQuality = 'high';

                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        // Get the scaled base64 image from the canvas
                        const scaledBase64 = canvas.toDataURL();

                        // Remove the MIME type prefix before resolving
                        const strippedBase64 = scaledBase64.replace(/^data:[^,]+,/, '');

                        // Resolve the promise with the scaled base64 image
                        resolve(strippedBase64);
                    };

                    img.onerror = (err) => {
                        console.error('Error loading the base64 image:', err);
                        reject(err);
                    };
                });
            },

            loadImage(url) {
                const canvas = this.$refs.canvas;
                const img = new Image();

                img.onload = () => {
                    this.canvasImage = img; // Store the loaded image in a Vue reactive property

                    // Adjust canvas dimensions to match the image dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;

                    console.log("Image loaded: ", img.width, img.height);

                    // Redraw the canvas with the loaded image
                    this.redrawCanvas();
                };

                img.src = url;
            },

            getFinalImage() {
                const canvas = this.$refs.canvas;
                const base64Image = canvas.toDataURL('image/png');
                return base64Image;
            },

            isSecondaryButton(toolToBeSelected) {
                if ((this.drawMode == 'select' || this.drawMode == 'move') && this.selectedObject?.type == toolToBeSelected) return true;
                return false;
            },

            keydownHandler(event) {
                const { key } = event;

                if (!this.showDrawTools) return;
                if (this.isTyping) return;

                if (event.ctrlKey && key === "z") { this.undoDraw(); }
                if (event.ctrlKey && key === "y") { this.redoDraw(); }

                if (event.shiftKey && key === "Delete") { this.clearAllDraws(); }

                if (key == "Escape") { 
                    this.selectTool(null); 
                    this.selectedObject = null;
                    this.selectionBox = null;
                    this.selectedIndex = -1;
                    this.redrawCanvas();
                }
                if (key == "Delete") { this.deleteSelectedObject(); }

                if (key == 'p') { this.selectTool('freehand'); }
                if (key == 'l') { this.selectTool('line'); }
                if (key == 't') { this.selectTool('textbox'); }
                if (key == 'x') { this.selectTool('x'); }
                if (key == 'o') { this.selectTool('o'); }

                if (key == 's' && !this.selectButtonDisabled) { this.selectTool('select'); }
                if (key == 'm' && !this.moveButtonDisabled) { this.selectTool('move'); }
                if (key == 'r' && !this.resizeButtonDisabled) { this.selectTool('resize'); }

                if (key == '1') { this.drawColor = this.colorOptions.black; }
                if (key == '2') { this.drawColor = this.colorOptions.white; }
                if (key == '3') { this.drawColor = this.colorOptions.red; }
                if (key == '4') { this.drawColor = this.colorOptions.orange; }
                if (key == '5') { this.drawColor = this.colorOptions.yellow; }
                if (key == '6') { this.drawColor = this.colorOptions.green; }
                if (key == '7') { this.drawColor = this.colorOptions.blue; }
                if (key == '8') { this.drawColor = this.colorOptions.purple; }

                if (this.drawMode == 'select') {
                    if (key == 'ArrowRight') {
                        this.selectedIndex += 1;
                        
                        // Check if the selected index is out of bounds and roll over
                        if (this.selectedIndex >= this.history.length) {
                            this.selectedIndex = 0;
                        }
                        // If not -1, select the object at the new index from history
                        if (this.selectedIndex != -1) {
                            console.log("Selected index:", this.selectedIndex);
                            this.selectObject(this.history[this.selectedIndex]);
                        }
                    }
                    if (key == 'ArrowLeft') {
                        this.selectedIndex -= 1;

                        // Check if the selected index is out of bounds and roll over
                        if (this.selectedIndex < 0) {
                            this.selectedIndex = this.history.length - 1;
                        }

                        // If not -1, select the object at the new index from history
                        if (this.selectedIndex != -1) {
                            console.log("Selected index:", this.selectedIndex);
                            this.selectObject(this.history[this.selectedIndex]);
                        }
                    }
                }

                // if (key == 'ArrowUp') { this.moveSelectedObject(0, -1); }


                // Check the specific keys
                if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Shift", "Enter", " "].includes(key)) {
                    console.log(`Key pressed: ${key}`);
                }

                if (event.shiftKey && event.key === "ArrowRight") {
                    console.log("Shift + ArrowRight pressed!");
                    // Add your custom logic here
                }
            },

            selectTool(tool) {
                if (this.drawMode == tool) {
                    this.drawMode = null;
                    if (this.selectedObject) {
                        this.selectedObject = null;
                        this.selectionBox = null;
                        this.redrawCanvas();
                    }
                    return;
                }

                this.drawMode = tool;
                if (tool != 'select' && tool != 'move' && tool != 'resize') {
                    if (this.selectedObject) {
                        this.selectedObject = null;
                        this.selectionBox = null;
                    }
                }
                this.redrawCanvas();
            },

            undoDraw() {
                if (this.history.length === 0) return;

                const lastDraw = this.history.pop();
                
                // Save the current state before undo for redo
                const currentState = { ...lastDraw.object };

                switch (lastDraw.type) {
                    case 'delete':
                        console.log("Undoing delete action:", lastDraw);

                        // Re-add the deleted object to the history at its original index
                        this.history.splice(lastDraw.index, 0, lastDraw.object);

                        // Push the delete action to the redo stack
                        this.redoStack.push(lastDraw);

                        // Reselect the object (optional)
                        this.selectedObject = lastDraw.object;
                        this.redrawCanvas();
                        break;
                    case 'modify':
                        // Restore the object's previous state
                        Object.assign(lastDraw.object, lastDraw.previousState);

                        // Push the current state to the redo stack
                        this.redoStack.push({
                            type: 'modify',
                            object: lastDraw.object,
                            previousState: currentState,
                        });

                        // If the modified object is selected, update the selection box
                        this.redrawCanvas();
                        break;

                    case 'freehand':
                        // Push the last freehand action to the redo stack
                        this.redoStack.push(lastDraw);

                        // Check if the undone action was the currently selected object
                        if (this.selectedObject === lastDraw) {
                            this.selectedObject = null;
                            this.selectionBox = null;
                        }

                        this.redrawCanvas();
                        break;
                    case 'line':
                        // Push the last line action to the redo stack
                        this.redoStack.push(lastDraw);

                        // Check if the undone action was the currently selected object
                        if (this.selectedObject === lastDraw) {
                            this.selectedObject = null;
                            this.selectionBox = null;
                        }

                        this.redrawCanvas();
                        break;
                    case 'x':
                        // Push the last X action to the redo stack
                        this.redoStack.push(lastDraw);

                        // Check if the undone action was the currently selected object
                        if (this.selectedObject === lastDraw) {
                            this.selectedObject = null;
                            this.selectionBox = null;
                        }

                        this.redrawCanvas();
                        break;
                    case 'o':
                        // Push the last O action to the redo stack
                        this.redoStack.push(lastDraw);

                        // Check if the undone action was the currently selected object
                        if (this.selectedObject === lastDraw) {
                            this.selectedObject = null;
                            this.selectionBox = null;
                        }

                        this.redrawCanvas();
                        break;
                    default:
                        console.log("Unknown action type in undo:", lastDraw.type);
                        break;
                }
            },

            redoDraw() {
                if (this.redoStack.length === 0) return;

                const lastRedo = this.redoStack.pop();

                // Save the current state before redo for undo
                const currentState = { ...lastRedo.object };
                const deleteIndex = this.history.indexOf(lastRedo.object);

                switch (lastRedo.type) {
                    case 'delete':
                        console.log("Redoing delete action:", lastRedo);

                        // Remove the object from the history again
                        if (deleteIndex > -1) {
                            this.history.splice(deleteIndex, 1);
                        }

                        // Push the delete action back to the history
                        this.history.push(lastRedo);

                        // Clear selection and bounding box if necessary
                        if (this.selectedObject === lastRedo.object) {
                            this.selectedObject = null;
                            this.selectionBox = null;
                        }

                        this.redrawCanvas();
                        break;
                    case 'modify':
                        // Reapply the changes to the object
                        Object.assign(lastRedo.object, lastRedo.previousState);

                        // Push the redone action back to the history stack
                        this.history.push({
                            type: 'modify',
                            object: lastRedo.object,
                            previousState: currentState,
                        });

                        // If the modified object is selected, update the selection box
                        this.redrawCanvas();
                        break;

                    case 'freehand':
                        // Push the last freehand action back into the history stack
                        this.history.push(lastRedo);
                        this.redrawCanvas();
                        break;
                    case 'line':
                        // Push the last line action back into the history stack
                        this.history.push(lastRedo);
                        this.redrawCanvas();
                        break;
                    case 'x':
                        // Push the last X action back into the history stack
                        this.history.push(lastRedo);
                        this.redrawCanvas();
                        break;
                    case 'o':
                        // Push the last O action back into the history stack
                        this.history.push(lastRedo);
                        this.redrawCanvas();
                        break;
                        
                    default:
                        console.log("Unknown action type in redo:", lastRedo.type);
                        break;
                }
            },

            changeProperty(object, property, newValue) {
                if (!object) return;

                // Ensure the object has an ID for uniqueness
                if (!object.id) {
                    console.warn("Object is missing an ID. Generating a new unique ID.");
                    object.id = this.generateUniqueId(); // Generate a secure unique ID
                }

                // Check if the last history entry is a modification of the same object and property
                const lastAction = this.history[this.history.length - 1];
                if (
                    lastAction &&
                    lastAction?.type === "modify" &&
                    lastAction?.object?.id === object?.id &&
                    lastAction?.property === property && 
                    property != 'color'
                ) {
                    // Overwrite the last modification instead of adding a new one
                    console.log("Overwriting last modification to prevent redundant history entries.");
                    lastAction.previousState = { ...lastAction.previousState }; // Keep the previous state unchanged
                    object[property] = newValue; // Update the object property
                    return;
                }

                // Save the current state before making a new change
                const previousState = { ...object };

                // Update the object's property
                object[property] = newValue;

                // Push the change into the history stack
                this.history.push({
                    type: "modify",
                    object: object,
                    property: property,
                    previousState: previousState,
                });

                console.log("Property changed:", object, property, newValue);

                // Clear the redo stack because we're creating a new change
                this.redoStack = [];
                console.log("Redo stack cleared due to new change.");
            },

            deleteSelectedObject() {
                if (!this.selectedObject) return; // No selected object to delete

                console.log("Deleting selected object:", this.selectedObject);

                // Remove the selected object from history
                const deleteIndex = this.history.indexOf(this.selectedObject);
                if (deleteIndex > -1) {
                    // Push the delete action to the history
                    this.history.push({
                        type: 'delete',
                        object: this.selectedObject,
                        index: deleteIndex, // Save the index for restoration
                    });

                    // Remove the object from the history stack
                    this.history.splice(deleteIndex, 1);

                    // Clear selection and selection box
                    this.selectedObject = null;
                    this.selectionBox = null;

                    // Redraw the canvas
                    this.redrawCanvas();

                    console.log("Delete action completed. History:", this.history);
                }
            },

            async clearAllDraws() {

                let shouldDelete = await swal({
                    title: 'Er du sikker?',
                    text: 'Du er ved at slette alle tegninger på billedet',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                });    
                
                if (!shouldDelete) {
                    return;
                }

                this.history = [];
                this.redoStack = [];
                this.selectedObject = null;
                this.selectionBox = null;
                this.redrawCanvas();
            },

            calculateLineCorners(start, end, lineWidth) {
                const dx = end.x - start.x;
                const dy = end.y - start.y;

                // Calculate the length of the line segment
                const length = Math.hypot(dx, dy);

                // Normalize the perpendicular vector
                const perpX = -(dy / length);
                const perpY = dx / length;

                // Scale the perpendicular vector by half the line width
                const offsetX = (perpX * lineWidth) / 2;
                const offsetY = (perpY * lineWidth) / 2;

                // Calculate the four corners
                const corner1 = { x: start.x + offsetX, y: start.y + offsetY };
                const corner2 = { x: start.x - offsetX, y: start.y - offsetY };
                const corner3 = { x: end.x + offsetX, y: end.y + offsetY };
                const corner4 = { x: end.x - offsetX, y: end.y - offsetY };

                return [corner1, corner2, corner3, corner4];
            },

            redrawCanvas() {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext("2d");

                // Clear the canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Redraw the source image
                if (this.canvasImage) {
                    ctx.drawImage(this.canvasImage, 0, 0, canvas.width, canvas.height);
                }

                // Redraw all objects from the history
                this.history.forEach((action) => {
                    if (action.type === "freehand") {
                        this.drawFreehand(action.path, action.color, action.lineWidth);
                        if (this.selectedObject && this.selectedObject.id === action.id) {
                            this.selectionBox = this.getBoundingBox(action.path);
                        }
                    }
                    if (action.type === "line") {
                        this.drawLine(action.start, action.end, action.color, action.lineWidth);
                        if (this.selectedObject && this.selectedObject.id === action.id) {
                            this.selectionBox = this.getBoundingBox(this.calculateLineCorners(action.start, action.end, action.lineWidth));
                        }
                    }
                    if (action.type === "textbox") {
                        action.end = this.drawTextBox(action.start, action.text, action.color, action.fontSize, action.backgroundColor);
                        if (this.selectedObject && this.selectedObject.id === action.id) {
                            this.selectionBox = this.getBoundingBox([action.start, action.end]);
                        }
                    }
                    if (action.type === "x") {
                        this.drawX(action.center, action.color, action.lineWidth, action.size);
                    }
                    if (action.type === "o") {
                        this.drawO(action.center, action.color, action.lineWidth, action.size);
                    }
                    if (action.type === "x" || action.type === "o") {
                        if (this.selectedObject && this.selectedObject.id === action.id) {
                            const topLeft = { x: action.center.x - (action.size / 2) - (action.lineWidth / 2), y: action.center.y - (action.size / 2) - (action.lineWidth / 2) };
                            const bottomRight = { x: action.center.x + (action.size / 2) + (action.lineWidth / 2), y: action.center.y + (action.size / 2) + (action.lineWidth / 2) };
                            this.selectionBox = this.getBoundingBox([topLeft, bottomRight]);
                        }
                    }
                });

                // Draw the bounding box for the selected object
                this.drawSelectionBox();
            },

            selectObject(object) {
                this.selectedObject = object;
                if (object?.color) {
                    this.drawColor = object.color;
                }

                if (object?.backgroundColor) {
                    this.textBoxBackground = object.backgroundColor;
                }

                if (object?.fontSize) {
                    this.fontSize = object.fontSize;
                }

                if (object?.lineWidth) {
                    this.lineWidth = object.lineWidth;
                }

                if (object?.text) {
                    this.textBoxContent = object.text;
                }

                console.log("Selected object:", object);

                this.redrawCanvas();
            },

            moveSelectedObject(dx, dy){
                console.log("Moving selected object", dx, dy);
            },

            onMouseDown(event) {
                const canvas = this.$refs.canvas;
                const mousePos = this.getMousePosition(canvas, event);

                switch (this.drawMode) {
                    case 'freehand':
                        this.isDrawing = true;
                        this.currentPath = [mousePos];
                        break;
                    case 'line':
                        this.isDrawing = true;
                        this.currentPath = [mousePos];
                        break;
                    case 'textbox':
                        this.isDrawing = true;
                        this.currentPath = [mousePos];
                        break;
                    case 'x': 
                        this.isDrawing = true;
                        this.currentPath = [mousePos];
                        break;
                    case 'o':
                        this.isDrawing = true;
                        this.currentPath = [mousePos];
                        break;
                    case 'select':
                        this.selectedObject = null;
                        this.selectionBox = null;

                        for (let i = 0; i < this.history.length; i++) {
                            const action = this.history[i];
                            if (
                                action.type === 'freehand' &&
                                this.isPointInPath(mousePos, action.path, action.lineWidth + 2)
                            ) {
                                this.selectObject(action);
                                break;
                            }
                            if (action.type === 'line') {
                                console.log("Checking line selection");
                                const { start, end } = action;
                                if (this.isPointOnLine(mousePos, start, end, (action.lineWidth / 2) + 2)) {
                                    this.selectObject(action);
                                    break;
                                }
                            }
                            if (action.type === 'textbox') {
                                console.log("Checking textbox selection");
                                const { start, end } = action;
                                if (this.isPointInRect(mousePos, start, end)) {
                                    this.selectObject(action);
                                    break;
                                }
                            }
                            if (action.type === 'x') {
                                const { center, size } = action;
                                const halfSize = size / 2;

                                const topLeft = { x: center.x - halfSize, y: center.y - halfSize };
                                const topRight = { x: center.x + halfSize, y: center.y - halfSize };
                                const bottomLeft = { x: center.x - halfSize, y: center.y + halfSize };
                                const bottomRight = { x: center.x + halfSize, y: center.y + halfSize };

                                if (this.isPointOnLine(mousePos, topLeft, bottomRight, (action.lineWidth / 2) + 2) ||
                                    this.isPointOnLine(mousePos, topRight, bottomLeft, (action.lineWidth / 2) + 2)) {
                                    this.selectObject(action);
                                    break;
                                }
                            }
                            if (action.type === 'o') {
                                const { center, size } = action;
                                const halfSize = size / 2;

                                if (this.isPointOnCircle(mousePos, center, halfSize, action.lineWidth + 2)) {
                                    this.selectObject(action);
                                    break;
                                }
                            }
                        }
                        // this.redrawCanvas();
                        break;

                    case 'move':
                        if (this.selectedObject && this.selectedObject.type === 'freehand') {
                            this.isDragging = true;
                            this.dragStart = mousePos;

                            // Save the original path state for undo
                            this.dragStartPath = this.selectedObject.path.map((point) => ({ ...point }));
                        }
                        if (this.selectedObject && this.selectedObject.type === 'line' || this.selectedObject.type === 'textbox') {
                            this.isDragging = true;
                            this.dragStart = mousePos;

                            // Save the original line state for undo
                            this.dragStartPath = [
                                { ...this.selectedObject.start },
                                { ...this.selectedObject.end },
                            ];
                        }
                        if (this.selectedObject && (this.selectedObject.type === 'x' || this.selectedObject.type === 'o')) {
                            this.isDragging = true;
                            this.dragStart = mousePos;

                            // Save the original X state for undo
                            this.dragStartPath = [
                                { ...this.selectedObject.center },
                            ];
                        }
                        break;

                    case 'resize':
                        this.handleStartResize(mousePos);
                        break;

                    default:
                        console.log(`Unhandled drawMode: ${this.drawMode}`);
                        break;
                }
            },

            onMouseMove(event) {
                const canvas = this.$refs.canvas;
                const mousePos = this.getMousePosition(canvas, event);

                switch (this.drawMode) {
                    case 'freehand':
                        if (this.isDrawing) {
                            this.currentPath.push(mousePos);
                            this.drawFreehand(this.currentPath);
                        }
                        break;
                    case 'line':
                        if (this.isDrawing) {
                            this.currentPath.push(mousePos);
                            this.redrawCanvas();
                            this.drawLine(this.currentPath[0], mousePos);
                        }
                        break;
                    case 'textbox':
                        if (this.isDrawing) {
                            this.currentPath.push(mousePos);
                            this.redrawCanvas();
                            this.textBoxEndPoint = this.drawTextBox(mousePos);
                        }
                        break;
                    case 'x':
                        if (this.isDrawing) {
                            this.currentPath.push(mousePos);
                            this.redrawCanvas();
                            this.drawX(mousePos);
                        }
                        break;
                    case 'o':
                        if (this.isDrawing) {
                            this.currentPath.push(mousePos);
                            this.redrawCanvas();
                            this.drawO(mousePos);
                        }
                        break;
                    case 'move':
                        if (this.isDragging && this.selectedObject?.type === 'freehand') {
                            const dx = mousePos.x - this.dragStart.x;
                            const dy = mousePos.y - this.dragStart.y;

                            // Update object's position (assumes path needs to be moved)
                            this.selectedObject.path = this.selectedObject.path.map((point) => ({
                                x: point.x + dx,
                                y: point.y + dy,
                            }));

                            // Update dragStart for smooth movement
                            this.dragStart = mousePos;

                            // Redraw canvas with updated object position
                            this.redrawCanvas();
                        }
                        if (this.isDragging && (this.selectedObject?.type === 'line' || this.selectedObject?.type === 'textbox')) {
                            const dx = mousePos.x - this.dragStart.x;
                            const dy = mousePos.y - this.dragStart.y;

                            // Update object's position (assumes path needs to be moved)
                            this.selectedObject.start = { x: this.dragStartPath[0].x + dx, y: this.dragStartPath[0].y + dy };
                            this.selectedObject.end = { x: this.dragStartPath[1].x + dx, y: this.dragStartPath[1].y + dy };

                            // Redraw canvas with updated object position
                            this.redrawCanvas();
                        }
                        if (this.isDragging && (this.selectedObject?.type === 'x' || this.selectedObject?.type === 'o')) {
                            const dx = mousePos.x - this.dragStart.x;
                            const dy = mousePos.y - this.dragStart.y;

                            // Update object's position (assumes path needs to be moved)
                            this.selectedObject.center = { x: this.dragStartPath[0].x + dx, y: this.dragStartPath[0].y + dy };

                            // Redraw canvas with updated object position
                            this.redrawCanvas();
                        }
                        break;
                    case 'resize':
                        this.handleResize(mousePos);
                        break;

                    default:
                        // Other modes can be handled here if needed
                        break;
                }
            },

            onMouseUp() {
                switch (this.drawMode) {
                    case 'freehand':
                        if (this.isDrawing) {
                            this.isDrawing = false;
                            this.history.push({
                                id: this.generateUniqueId(),
                                type: 'freehand',
                                path: [...this.currentPath],
                                color: this.drawColor,
                                lineWidth: this.lineWidth,
                            });
                            this.currentPath = [];
                        }
                        break;
                    case 'line':
                        if (this.isDrawing) {
                            this.isDrawing = false;
                            this.history.push({
                                id: this.generateUniqueId(),
                                type: 'line',
                                start: this.currentPath[0],
                                end: this.currentPath[this.currentPath.length - 1],
                                color: this.drawColor,
                                lineWidth: this.lineWidth,
                            });
                            this.currentPath = [];
                        }
                        break;
                    case 'textbox':
                        if (this.isDrawing) {
                            this.isDrawing = false;
                            this.history.push({
                                id: this.generateUniqueId(),
                                type: 'textbox',
                                start: this.currentPath[this.currentPath.length - 1],
                                end: this.textBoxEndPoint,
                                color: this.drawColor,
                                text: this.textBoxContent,
                                fontSize: this.fontSize,
                                backgroundColor: this.textBoxBackground,
                            });
                            this.currentPath = [];
                        }
                        break;
                    case 'x':
                        if (this.isDrawing) {
                            this.isDrawing = false;
                            this.history.push({
                                id: this.generateUniqueId(),
                                type: 'x',
                                center: this.currentPath[this.currentPath.length - 1],
                                size: this.size,
                                color: this.drawColor,
                                lineWidth: this.lineWidth,
                            });
                            this.currentPath = [];
                        }
                        break;
                    case 'o':
                        if (this.isDrawing) {
                            this.isDrawing = false;
                            this.history.push({
                                id: this.generateUniqueId(),
                                type: 'o',
                                center: this.currentPath[this.currentPath.length - 1],
                                size: this.size,
                                color: this.drawColor,
                                lineWidth: this.lineWidth,
                            });
                            this.currentPath = [];
                        }
                        break;
                    case 'move':
                        if (this.isDragging && this.selectedObject?.type === 'freehand') {
                            this.isDragging = false;

                            // Save the move as a "modify" action in history for undo/redo
                            const previousPath = this.dragStartPath.map((point) => ({ ...point })); // Path before moving
                            const newPath = this.selectedObject.path.map((point) => ({ ...point })); // Path after moving

                            this.history.push({
                                type: 'modify',
                                object: this.selectedObject,
                                previousState: { path: previousPath }, // Record the original state
                                newState: { path: newPath }, // Record the new state
                            });

                            // Clear the dragStartPath reference
                            this.dragStartPath = null;
                        }
                        if (this.isDragging && this.selectedObject?.type === 'line' || this.selectedObject?.type === 'textbox') {
                            this.isDragging = false;

                            // Save the move as a "modify" action in history for undo/redo
                            const previousPath = this.dragStartPath.map((point) => ({ ...point })); // Path before moving
                            const newPath = [
                                { ...this.selectedObject.start },
                                { ...this.selectedObject.end },
                            ]; // Path after moving

                            this.history.push({
                                type: 'modify',
                                object: this.selectedObject,
                                previousState: { start: previousPath[0], end: previousPath[1] }, // Record the original state
                                newState: { start: newPath[0], end: newPath[1] }, // Record the new state
                            });

                            // Clear the dragStartPath reference
                            this.dragStartPath = null;
                        }
                        if (this.isDragging && (this.selectedObject?.type === 'x' || this.selectedObject?.type === 'o')) {
                            this.isDragging = false;

                            // Save the move as a "modify" action in history for undo/redo
                            const previousPath = this.dragStartPath.map((point) => ({ ...point })); // Path before moving
                            const newPath = [
                                { ...this.selectedObject.center },
                            ]; // Path after moving

                            this.history.push({
                                type: 'modify',
                                object: this.selectedObject,
                                previousState: { center: previousPath[0] }, // Record the original state
                                newState: { center: newPath[0] }, // Record the new state
                            });

                            // Clear the dragStartPath reference
                            this.dragStartPath = null;
                        }
                        break;
                    case 'resize':
                    if (this.isResizing && this.selectedObject) {
                        this.isResizing = false;

                        let prevState = { ...this.selectedObject };
                        prevState.path = this.initialPath.map((point) => ({ ...point }));

                        // Save resize action to history
                        this.history.push({
                            type: 'modify',
                            object: this.selectedObject,
                            previousState: prevState,
                        });

                        this.redoStack = []; // Clear redo stack on new action
                    }
                    break;

                    default:
                        // Handle other cases if necessary
                        break;
                }
            },

            pointsAreClose(point1, point2, threshold = 5) {
                // Calculate the distance using the Euclidean formula
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const distance = Math.hypot(dx, dy); // Equivalent to Math.sqrt(dx * dx + dy * dy)

                // Return true if the distance is smaller than the threshold
                return distance < threshold;
            },

            handleStartResize(mousePos) {
                const handlePositions = this.calcHandles();

                const handle = handlePositions.find(
                    (pos) =>
                        mousePos.x >= pos.x - (this.handleSize - 2) / 2 &&
                        mousePos.x <= pos.x + (this.handleSize - 2) / 2 &&
                        mousePos.y >= pos.y - (this.handleSize - 2) / 2 &&
                        mousePos.y <= pos.y + (this.handleSize - 2) / 2
                );

                if (!handle) return;

                if (this.selectedObject.type === 'freehand') {
                    this.isResizing = true;
                    this.resizeHandle = handle.type;
                    this.initialMousePos = mousePos;
                    this.initialBoundingBox = { ...this.selectionBox };
                    this.initialPath = this.selectedObject.path.map((point) => ({ ...point }));
                    return;
                }
                else if (this.selectedObject.type === 'line' && this.pointsAreClose(mousePos, this.selectedObject.start) || this.pointsAreClose(mousePos, this.selectedObject.end)) {
                    this.isResizing = true;
                    this.resizeHandle = handle.type;
                    this.movePoint = null;
                    this.initialMousePos = mousePos;
                    this.initialBoundingBox = { ...this.selectionBox };
                    this.initialPath = [
                        { ...this.selectedObject.start },
                        { ...this.selectedObject.end },
                    ];
                    return;
                }
            },

            handleResize(mousePos) {
                if (this.isResizing && this.selectedObject) {
                    const dx = mousePos.x - this.initialMousePos.x;
                    const dy = mousePos.y - this.initialMousePos.y;

                    const { x, y, width, height } = this.initialBoundingBox;

                    let newWidth = width;
                    let newHeight = height;

                    switch (this.resizeHandle) {
                        case 'top-left':
                            newWidth = width - dx;
                            newHeight = height - dy;
                            break;
                        case 'top-middle': 
                            newWidth = width;
                            newHeight = height - dy;
                            break;
                        case 'top-right':
                            newWidth = width + dx;
                            newHeight = height - dy;
                            break;
                        case 'bottom-left':
                            newWidth = width - dx;
                            newHeight = height + dy;
                            break;
                        case 'bottom-middle':
                            newWidth = width;
                            newHeight = height + dy;
                            break;
                        case 'bottom-right':
                            newWidth = width + dx;
                            newHeight = height + dy;
                            break;
                        case 'middle-left':
                            newWidth = width - dx;
                            newHeight = height;
                            break;
                        case 'middle-right':
                            newWidth = width + dx;
                            newHeight = height;
                            break;
                    }

                    const scaleBoxX = newWidth / width;
                    const scaleBoxY = newHeight / height;

                    if (this.selectedObject.type === 'freehand') {
                        this.selectedObject.path = this.initialPath.map((point) => {
                            // Calculate the relative position of the point to the top-left corner (or the origin of the selection box)
                            const relativeX = point.x - x;
                            const relativeY = point.y - y;

                            // If keeping aspect ratio, calculate a uniform scale factor
                            let finalScaleX = scaleBoxX;
                            let finalScaleY = scaleBoxY;

                            if (this.keepAspectRatio && ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(this.resizeHandle)) {
                                const uniformScale = Math.min(scaleBoxX, scaleBoxY);
                                finalScaleX = uniformScale;
                                finalScaleY = uniformScale;
                            }

                            // Apply scaling to the relative position
                            const scaledX = relativeX * finalScaleX;
                            const scaledY = relativeY * finalScaleY;

                            // Reposition the scaled object based on the new bounding box position (offset)
                            const finalX = scaledX + x;
                            const finalY = scaledY + y;

                            switch (this.resizeHandle) {
                                case 'top-left':
                                    return { x: finalX + dx, y: finalY + dy };
                                case 'top-middle':
                                    return { x: point.x, y: finalY + dy };
                                case 'top-right':
                                    return { x: finalX, y: finalY + dy };
                                case 'bottom-left':
                                    return { x: finalX + dx, y: finalY };
                                case 'bottom-middle':
                                    return { x: point.x, y: finalY };
                                case 'bottom-right':
                                    return { x: finalX, y: finalY };
                                case 'middle-left':
                                    return { x: finalX + dx, y: point.y };
                                case 'middle-right':
                                    return { x: finalX, y: point.y };
                                default:
                                    console.log("Unhandled resize handle:", this.resizeHandle);
                                    return point;
                            }
                        });
                        let newBoundingBox = this.getBoundingBox(this.selectedObject.path);
                        let before = {x: 0, y: 0};
                        let after = {x: 0, y: 0};
                        let dxCorner = 0;
                        let dyCorner = 0;
                        switch (this.resizeHandle) {
                            case 'top-left':
                                // Calculate the displacement of the bottom right corner
                                before = { x: this.initialBoundingBox.x + this.initialBoundingBox.width, y: this.initialBoundingBox.y + this.initialBoundingBox.height };
                                after = { x: newBoundingBox.x + newBoundingBox.width, y: newBoundingBox.y + newBoundingBox.height };
                                dxCorner = after.x - before.x;
                                dyCorner = after.y - before.y;

                                // Move the bottom right corner to keep the top left corner fixed
                                this.selectedObject.path = this.selectedObject.path.map((point) => {
                                    return { x: point.x - dxCorner, y: point.y - dyCorner };
                                });

                                break;
                            case 'top-right': 
                                // Calculate the displacement of the bottom left corner
                                before = { x: this.initialBoundingBox.x, y: this.initialBoundingBox.y + this.initialBoundingBox.height };
                                after = { x: newBoundingBox.x, y: newBoundingBox.y + newBoundingBox.height };
                                dxCorner = after.x - before.x;
                                dyCorner = after.y - before.y;

                                // Move the bottom left corner to keep the top right corner fixed
                                this.selectedObject.path = this.selectedObject.path.map((point) => {
                                    return { x: point.x - dxCorner, y: point.y - dyCorner };
                                });
                                break;
                            case 'bottom-left':
                                // Calculate the displacement of the top right corner
                                before = { x: this.initialBoundingBox.x + this.initialBoundingBox.width, y: this.initialBoundingBox.y };
                                after = { x: newBoundingBox.x + newBoundingBox.width, y: newBoundingBox.y };
                                dxCorner = after.x - before.x;
                                dyCorner = after.y - before.y;

                                // Move the top right corner to keep the bottom left corner fixed
                                this.selectedObject.path = this.selectedObject.path.map((point) => {
                                    return { x: point.x - dxCorner, y: point.y - dyCorner };
                                });
                                break;
                            case 'bottom-right':
                                // Calculate the displacement of the top left corner
                                before = { x: this.initialBoundingBox.x, y: this.initialBoundingBox.y };
                                after = { x: newBoundingBox.x, y: newBoundingBox.y };
                                dxCorner = after.x - before.x;
                                dyCorner = after.y - before.y;

                                // Move the top left corner to keep the bottom right corner fixed
                                this.selectedObject.path = this.selectedObject.path.map((point) => {
                                    return { x: point.x - dxCorner, y: point.y - dyCorner };
                                });
                                break;
                        }
                    } 

                    if (this.selectedObject.type === 'line') {
                        const handlePositions = this.calcHandles();
                        console.log("Handle positions:", handlePositions);
                        
                        // Find what endpoint to move by checking the distance between handle and endpoints
                        if (!this.movePoint) {
                            const handle = handlePositions.find((pos) => pos.type === this.resizeHandle);
                            const distanceStart = Math.hypot(
                                handle.x - this.selectedObject.start.x,
                                handle.y - this.selectedObject.start.y
                            );
                            const distanceEnd = Math.hypot(
                                handle.x - this.selectedObject.end.x,
                                handle.y - this.selectedObject.end.y
                            );

                            console.log("Distance start:", distanceStart, "Distance end:", distanceEnd);
                            if (distanceStart < distanceEnd) {
                                this.movePoint = 'start';
                            } else {
                                this.movePoint = 'end';
                            }
                        }
                        
                        if (this.movePoint === 'start') {
                            console.log("Moving start point");
                            this.selectedObject.start = {
                                x: this.initialPath[0].x + dx,
                                y: this.initialPath[0].y + dy,
                            };
                        } else {
                            console.log("Moving end point");
                            this.selectedObject.end = {
                                x: this.initialPath[1].x + dx,
                                y: this.initialPath[1].y + dy,
                            };
                        }
                    }

                    this.redrawCanvas();
                }
            },

            getMousePosition(canvas, event) {
                if (event.type.startsWith('mouse')) {
                    const rect = canvas.getBoundingClientRect();
    
                    // Calculate scaling factors between the canvas's internal resolution and its display size
                    this.scaleX = canvas.width / rect.width;
                    this.scaleY = canvas.height / rect.height;
    
                    // Adjust mouse coordinates based on scaling factors
                    return {
                        x: (event.clientX - rect.left) * this.scaleX,
                        y: (event.clientY - rect.top) * this.scaleY
                    };
                }

                if (event.type.startsWith('touch')) {
                    const rect = canvas.getBoundingClientRect();
                    console.log("Touch event:", JSON.stringify(event));
                    console.log("Touch event touches:", JSON.stringify(event.touches[0]));
                    const touch = event.touches[0];

                    // Calculate scaling factors between the canvas's internal resolution and its display size
                    this.scaleX = canvas.width / rect.width;
                    this.scaleY = canvas.height / rect.height;

                    // Adjust touch coordinates based on scaling factors
                    return {
                        x: (touch.clientX - rect.left) * this.scaleX,
                        y: (touch.clientY - rect.top) * this.scaleY
                    };
                }

            },

            isPointInPath(point, path, tolerance = 5) {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.beginPath();
                ctx.moveTo(path[0].x, path[0].y);
                path.forEach((p) => {
                    ctx.lineTo(p.x, p.y);
                });

                // Set a wider line width for sensitivity (adjust tolerance as needed)
                ctx.lineWidth = tolerance;

                // Check if the point is near the stroke of the path
                return ctx.isPointInStroke(point.x, point.y);
            },

            isPointOnLine(point, start, end, tolerance = 5) {
                const length = Math.hypot(end.x - start.x, end.y - start.y);

                if (length === 0) {
                    // Start and end points are the same; check if point is within the tolerance of this single point
                    return Math.hypot(point.x - start.x, point.y - start.y) <= tolerance;
                }

                // Unit direction vector of the line
                const dx = (end.x - start.x) / length;
                const dy = (end.y - start.y) / length;

                // Project the point onto the line and calculate its perpendicular distance
                const projection = (point.x - start.x) * dx + (point.y - start.y) * dy;
                const perpendicularDistance = Math.abs(
                    (point.x - start.x) * dy - (point.y - start.y) * dx
                );

                // Check if the point lies within the segment boundaries
                if (projection < 0 || projection > length) {
                    return false; // Outside the segment
                }

                // Check if the perpendicular distance is within the tolerance
                return perpendicularDistance <= tolerance;
            },

            isPointOnCircle(point, center, radius, tolerance = 5) {
                // Calculate the distance between the point and the center of the circle
                const distance = Math.hypot(point.x - center.x, point.y - center.y);

                // Check if the distance is within the tolerance of the circle's radius
                return Math.abs(distance - radius) <= tolerance;
            },

            isPointInRect(point, start, end) {
                // Determine the boundaries of the rectangle
                const minX = Math.min(start.x, end.x);
                const maxX = Math.max(start.x, end.x);
                const minY = Math.min(start.y, end.y);
                const maxY = Math.max(start.y, end.y);

                // Check if the point lies within the boundaries
                return (
                    point.x >= minX &&
                    point.x <= maxX &&
                    point.y >= minY &&
                    point.y <= maxY
                );
            },

            drawFreehand(path, color = this.drawColor, lineWidth = this.lineWidth) {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;
                ctx.beginPath();
                path.forEach((point, index) => {
                if (index === 0) {
                    ctx.moveTo(point.x, point.y);
                } else {
                    ctx.lineTo(point.x, point.y);
                }
                });
                ctx.stroke();
            },

            drawLine(startPoint, endPoint, color = this.drawColor, lineWidth = this.lineWidth) {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;
                ctx.beginPath();

                // Move to the starting point
                ctx.moveTo(startPoint.x, startPoint.y);

                // Draw a line to the end point
                ctx.lineTo(endPoint.x, endPoint.y);

                // Render the line
                ctx.stroke();
            },

            drawX(startPoint, color = this.drawColor, lineWidth = this.lineWidth, size = this.size) {
                const ctx = this.$refs.canvas.getContext("2d");
                const halfSize = size / 2;

                // Calculate corner points of the square X
                const topLeft = { x: startPoint.x - halfSize, y: startPoint.y - halfSize };
                const topRight = { x: startPoint.x + halfSize, y: startPoint.y - halfSize };
                const bottomLeft = { x: startPoint.x - halfSize, y: startPoint.y + halfSize };
                const bottomRight = { x: startPoint.x + halfSize, y: startPoint.y + halfSize };

                // Draw the X
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;

                ctx.beginPath();
                // Draw the first diagonal (top-left to bottom-right)
                ctx.moveTo(topLeft.x, topLeft.y);
                ctx.lineTo(bottomRight.x, bottomRight.y);

                // Draw the second diagonal (top-right to bottom-left)
                ctx.moveTo(topRight.x, topRight.y);
                ctx.lineTo(bottomLeft.x, bottomLeft.y);

                ctx.stroke();
            },

            drawO(startPoint, color = this.drawColor, lineWidth = this.lineWidth, size = this.size) {
                const ctx = this.$refs.canvas.getContext("2d");
                const radius = size / 2;

                // Draw the circle
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;

                ctx.beginPath();
                ctx.arc(startPoint.x, startPoint.y, radius, 0, Math.PI * 2); // Full circle
                ctx.stroke();
            },

            drawTextBox(startPoint, text = this.textBoxContent, color = this.drawColor, fontSize = this.fontSize, backgroundColor = this.textBoxBackground, padding = 10) {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.save();

                // Set up text styles
                ctx.fillStyle = color;
                ctx.font = `${fontSize}px Arial`;
                ctx.textBaseline = "top"; // Align text to the top

                // Split the text into lines
                const textLines = text.split("\n");
                const lineHeight = fontSize * 1.2; // Adjust line height

                // Calculate the width and height for the text box
                const totalHeight = textLines.length * lineHeight + padding * 2;
                const maxWidth = Math.max(
                    ...textLines.map((line) => ctx.measureText(line).width)
                ) + padding * 2;

                // Draw the background rectangle with padding
                if (backgroundColor !== "transparent") {
                    ctx.fillStyle = backgroundColor;
                    ctx.fillRect(startPoint.x, startPoint.y, maxWidth, totalHeight);
                }

                // Draw the text with padding offset
                ctx.fillStyle = color; // Reset text color
                textLines.forEach((line, index) => {
                    const x = startPoint.x + padding;
                    const y = startPoint.y + padding + index * lineHeight;
                    ctx.fillText(line, x, y);
                });

                ctx.restore();

                const endPoint = { x: startPoint.x + maxWidth, y: startPoint.y + totalHeight };
                return endPoint;
            },

            getBoundingBox(path) {
                const padding = 2;
                let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;

                path.forEach(point => {
                    minX = Math.min(minX, point.x);
                    maxX = Math.max(maxX, point.x);
                    minY = Math.min(minY, point.y);
                    maxY = Math.max(maxY, point.y);
                });

                minX -= padding;
                if (minX < 0) minX = 0;
                minY -= padding;
                if (minY < 0) minY = 0;

                return { x: minX, y: minY, width: maxX - minX + (2 * padding), height: maxY - minY + (2 * padding) };
            },

            calcHandles() {
                
                if (this.selectedObject.type === 'line'){
                    const { start, end } = this.selectedObject;
                    return [
                        { x: start.x, y: start.y, type: 'start' }, // Start point
                        { x: end.x, y: end.y, type: 'end' }, // End point
                    ]
                } else {
                    const { x, y, width, height } = this.selectionBox;
                    // Define positions for handles (top-left, top-middle, top-right, bottom-left, bottom-middle, bottom-right, middle-left, middle-right)
                    return [
                        { x: x, y: y, type: 'top-left' }, // Top-left
                        { x: x + width / 2, y: y, type: 'top-middle' }, // Top-middle
                        { x: x + width, y: y, type: 'top-right' }, // Top-right
                        { x: x, y: y + height, type: 'bottom-left' }, // Bottom-left
                        { x: x + width / 2, y: y + height, type: 'bottom-middle' }, // Bottom-middle
                        { x: x + width, y: y + height, type: 'bottom-right' }, // Bottom-right
                        { x: x, y: y + height / 2, type: 'middle-left' }, // Middle-left
                        { x: x + width, y: y + height / 2, type: 'middle-right' }, // Middle-right
                    ];
                }

            },

            drawSelectionBox() {
                if (!this.selectionBox) return;
                
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.save();
                
                // Draw the main selection box
                ctx.strokeStyle = "rgba(255, 0, 255, 1)"; // Magenta color
                ctx.lineWidth = 4;
                ctx.setLineDash([5, 5]); // Dashed line
                const { x, y, width, height } = this.selectionBox;
                ctx.strokeRect(x, y, width, height);
                
                // If in 'resize' mode, draw resize handles
                if (this.drawMode === 'resize') {
                    console.log("Drawing selection box with resize handles");
                    const halfHandle = this.handleSize / 2;

                    // Define positions for handles (top-left, top-right, bottom-left, bottom-right)
                    const handlePositions = this.calcHandles();

                    // Draw handles as filled squares
                    ctx.fillStyle = "rgba(0, 255, 255, 1)"; // Cyan color
                    handlePositions.forEach((pos) => {
                        ctx.fillRect(pos.x - halfHandle, pos.y - halfHandle, this.handleSize, this.handleSize);
                    });
                }

                ctx.restore();
            },

            generateUniqueId() {
                return '_' + Math.random().toString(36).substr(2, 9);
            },

            compressHistory() {
                const compressedObjects = {};

                // Iterate through the history
                let deletedObjectIds = [];
                this.history.forEach(entry => {
                    if (entry.type === 'modify') {
                        const id = entry.object.id;

                        // Initialize the object in the compressedObjects map if not already present
                        if (!compressedObjects[id]) {
                            compressedObjects[id] = { ...entry.object }; // Copy object structure
                        }

                        // Apply the final value of the modified property
                        Object.assign(compressedObjects[id], entry.newState);
                    } 
                    else if (entry.type === 'delete') {
                        deletedObjectIds.push(entry.object.id);
                    }
                    else {
                        const id = entry.id;
                        compressedObjects[id] = { ...entry }; // Add new object 
                    }
                });

                let compressedList = Object.values(compressedObjects);
                compressedList = compressedList.filter(obj => !deletedObjectIds.includes(obj.id));

                // Return the compressed list of objects
                return compressedList;
            },
        },

        watch: {
            isOpen: {
                handler(open){
                    if (open) {
                        this.prePopulateForm();
                    }
                }
            },

            imageURL: {
                handler(url){
                    if (url) {
                        this.loadImage(url);
                    }
                }
            },

            drawColor: {
                handler(color){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'color', color);
                            this.redrawCanvas();
                        }
                    }
                }
            },

            lineWidth: {
                handler(width){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'lineWidth', width);
                            this.redrawCanvas();
                        }
                    }
                }
            },

            size: {
                handler(size){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'size', size);
                            this.redrawCanvas();
                        }
                    }
                }
            },

            fontSize: {
                handler(size){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'fontSize', size);
                            this.redrawCanvas();
                        }
                    }
                }
            },

            textBoxContent: {
                handler(content){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'text', content);
                            this.redrawCanvas();
                        }
                    }
                }
            },

            textBoxBackground: {
                handler(color){
                    if (this.drawMode == 'select') {
                        if (this.selectedObject) {
                            this.changeProperty(this.selectedObject, 'backgroundColor', color);
                            this.redrawCanvas();
                        }
                    }
                }
            },
        },

        mounted() {
            window.addEventListener('keydown', this.keydownHandler);
        },

        beforeDestroy() {
            window.removeEventListener('keydown', this.keydownHandler);
        },

    }
</script>

<style scoped>
    @import '@/assets/styles/buttons.css';
    .image-show-full {
        width: 700px;
        /* height: 400px; */
        /* border: 1px solid black; */
        /* margin-right: 10px; */
    }

    .image-show-full img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .form-field-spacing {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .actions-buttons {
        margin-top: 5px !important;
    }

    .canvas-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 500px; /* You can adjust this or let it be dynamic based on modal size */
        width: 100%;
    }

    .canvas-container.large {
        max-height: 800px; /* You can adjust this or let it be dynamic based on modal size */
    }

    canvas {
        max-height: 500px; /* Limit the height of the canvas to 500px */
        max-width: 100%; /* Limit the width of the canvas to the modal width */
        width: auto; /* Allow the width to scale based on the aspect ratio */
        height: auto; /* Allow the height to scale based on the aspect ratio */
    }

    canvas.large {
        max-height: 800px; /* Limit the height of the canvas to 800px */
    }

</style>