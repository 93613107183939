<template>
    <sui-modal
        small
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
    >
        <sui-modal-header>
            Forgæves kontakt
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-dimmer :active="loadingDimmerActive">
                            <multi-step-loader class="content" :steps="loadingSteps" />
                        </sui-dimmer>
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Send besked til:</sui-table-header-cell>
                        <sui-table-header-cell>Sidste besked</sui-table-header-cell>
                        <sui-table-header-cell>
                            <i v-if="selectedContactMethods.length == 0" @click="selectAllContactMethods" class="fa-regular fa-square" ></i>
                            <i v-else-if="selectedContactMethods.length < availableContactMethods.length" @click="selectAllContactMethods" class="fa-regular fa-square-minus" ></i>
                            <i v-else-if="selectedContactMethods.length == availableContactMethods.length" @click="deselectAllContactMethods" class="fa-regular fa-square-check"></i>
                        </sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <template v-for="contact in contacts">
                        <sui-table-row :key="contact.name">
                            <sui-table-cell colspan="3">{{contact.name}} ({{contact.role}})</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="contact.mobile" :key="`${contact.name} - mobile`">
                            <sui-table-cell>{{formatPhoneNumber(contact.mobile)}}</sui-table-cell>
                            <sui-table-cell>
                                <span v-if="checkIfMobile(contact.mobile)">{{latestMessages[contact.email]}}</span>
                            </sui-table-cell>
                            <sui-table-cell>
                                <i v-if="!checkIfMobile(contact.mobile)" class="fa-duotone fa-comment-slash" title="Dette nummer kan tilsyneladende ikke modtage SMS'er" style="--fa-primary-color: tomato; --fa-secondary-color: black;" ></i>
                                <i v-else-if="selectedContactMethods.includes(parsePhoneNumber(contact.mobile))"  @click="deselectContactMethod(parsePhoneNumber(contact.mobile))" class="fa-regular fa-square-check" ></i>
                                <i v-else  @click="selectContactMethod(parsePhoneNumber(contact.mobile))" class="fa-regular fa-square" ></i>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="contact.phone && contact.phone != contact.mobile" :key="`${contact.name} - phone`">
                            <sui-table-cell>{{formatPhoneNumber(contact.phone)}}</sui-table-cell>
                            <sui-table-cell>
                                <span v-if="checkIfMobile(contact.phone)">{{latestMessages[contact.phone]}}</span>
                            </sui-table-cell>
                            <sui-table-cell>
                                <i v-if="!checkIfMobile(contact.phone)" class="fa-duotone fa-comment-slash" title="Dette nummer kan tilsyneladende ikke modtage SMS'er" style="--fa-primary-color: tomato; --fa-secondary-color: black;" ></i>
                                <i v-else-if="selectedContactMethods.includes(parsePhoneNumber(contact.phone))" @click="deselectContactMethod(parsePhoneNumber(contact.phone))" class="fa-regular fa-square-check" ></i>
                                <i v-else  @click="selectContactMethod(parsePhoneNumber(contact.phone))" class="fa-regular fa-square" ></i>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="contact.email" :key="`${contact.name} - email`">
                            <sui-table-cell>{{contact.email}}</sui-table-cell>
                            <sui-table-cell>{{latestMessages[contact.email]}}</sui-table-cell>
                            <sui-table-cell>
                                <i v-if="selectedContactMethods.includes(contact.email.toLowerCase())" @click="deselectContactMethod(contact.email.toLowerCase())" class="fa-regular fa-square-check" ></i>
                                <i v-else class="fa-regular fa-square"  @click="selectContactMethod(contact.email.toLowerCase())" ></i>
                            </sui-table-cell>
                        </sui-table-row>
                    </template>
                </sui-table-body>
            </sui-table>
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>System-handlinger</sui-table-header-cell>
                        <sui-table-header-cell>
                            <i v-if="systemActionsSelected == systemActionCount" @click="deselectAllSystemActions" class="fa-regular fa-square-check" ></i>
                            <i v-else-if="systemActionsSelected > 0" @click="selectAllSystemActions" class="fa-regular fa-square-minus" ></i>
                            <i v-else @click="selectAllSystemActions" class="fa-regular fa-square" ></i>
                        </sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row>
                        <sui-table-cell>Tilføj ekstern note "forsøgt kontaktet telefonisk..."</sui-table-cell>
                        <sui-table-cell>
                            <i v-if="shouldAddExternalNote" @click="shouldAddExternalNote = false" class="fa-regular fa-square-check" ></i>
                            <i v-else @click="shouldAddExternalNote = true" class="fa-regular fa-square" ></i>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell>Tilføj arbejdsenhed "forgæves kontakt"</sui-table-cell>
                        <sui-table-cell>
                            <i v-if="shouldAddUnitWork" @click="shouldAddUnitWork = false" class="fa-regular fa-square-check" ></i>
                            <i v-else @click="shouldAddUnitWork = true" class="fa-regular fa-square" ></i>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button negative @click="closeModal">Anullér</sui-button>
            <sui-button positive @click="send" :disabled="selectedContactMethods.length == 0 && systemActionsSelected == 0">Send</sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { db } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import MultiStepLoader from '@/components/Global/MultiStepLoader'
import TaskCode from '../../lib/Enums/TaskCode'
// import swal from 'sweetalert'

export default {
    name: 'FailedContactModal',
    mixins: [Mixin, DateMixin, DataAPI, unitWorkMixin],
    components: {MultiStepLoader},
    props: {
        isOpen: {
            type: Boolean,
            required: false,
            default: false
        },
        contacts: {
            type: Array,
            required: true,
        },
        installation: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            selectedContactMethods: [],

            systemActionCount: 2,
            shouldAddExternalNote: true,
            shouldAddUnitWork: true,

            latestMessages: {},

            loadingDimmerActive: false,
            loadingSteps: [],
        }
    },

    computed: {
        availableContactMethods() {
            let contactMethods = []
            for (let contact of this.contacts) {
                let mobile = ''
                let phone = ''
                try {
                    mobile = this.parsePhoneNumber(contact.mobile)
                } catch {
                    mobile = contact.mobile
                }
                try {
                    phone = this.parsePhoneNumber(contact.phone)
                } catch {
                    phone = contact.phone
                }
                let email = contact.email.toLowerCase()
                if (mobile && !contactMethods.includes(mobile) && this.checkIfMobile(mobile)) {
                    contactMethods.push(mobile)
                }
                if (phone && !contactMethods.includes(phone) && this.checkIfMobile(phone)) {
                    contactMethods.push(phone)
                }
                if (email && !contactMethods.includes(email)) {
                    contactMethods.push(email)
                }
            }
            return contactMethods
        },
        systemActionsSelected() {
            let count = 0
            count += this.shouldAddExternalNote
            count += this.shouldAddUnitWork
            return count
        },
        bookTaskOrAny(){
            let booktask = this.installation.tasks.find(task => task.code == 'BOOK')
            if (booktask) return booktask
            return this.findFirstTask(this.installation.tasks)
        },
    },

    methods: {
        closeModal(){
            EventBus.$emit('failed-contact-modal-close')
        },

        async getLatestMessage(contactMethod){
            if (contactMethod.includes('@')){ //Method is an email address
                let firebaseResponse = await db.collection('SendgridEmail') //Get an email
                    .where('to', '==', contactMethod).where('delivery.state', '==', 'SUCCESS') //To the correct email addess, that was successfully delivered
                    .orderBy('delivery.endTime', 'desc').limit(1).get()
                // console.log(firebaseResponse)
                if (firebaseResponse.empty) return null
                else return firebaseResponse.docs[0].data().delivery.endTime
            } else { //Method must be a phone number
                let firebaseResponse = await db.collection('SMSMessages')
                    .where('Request.To', 'array-contains', this.parsePhoneNumber(contactMethod))
                    .orderBy('Response.ApiResponse.dateCreated', 'desc').limit(1).get()
                // console.log(firebaseResponse)
                if (firebaseResponse.empty) return null
                else return firebaseResponse.docs[0].data().Response.ApiResponse.dateCreated
            }
        },

        async getAllLatestMessages(){
            this.loadingSteps = [
                {
                    code: 'LOOP',
                    title: 'Loop igennem kontaktoplysninger',
                    state: 'in progress',
                },
            ]
            this.loadingDimmerActive = true
            try {
                for (let contactMethod of this.availableContactMethods) {
                    this.loadingSteps.push({
                        code: `METHOD_${contactMethod}`,
                        title: `Hent besked for ${contactMethod}`,
                        state: 'in progress'
                    })
                    this.getLatestMessage(contactMethod).then(latest => {
                        this.$set(this.latestMessages, contactMethod, this.toUserFriendlyTimestamp(latest, false))
                        this.changeLoadingStep(`METHOD_${contactMethod}`, 'success')
                    }).catch((error) => {
                        console.error('error getting message:', error)
                        this.changeLoadingStep(`METHOD_${contactMethod}`, 'error')
                    })
                }
                this.changeLoadingStep('LOOP', 'success')
                this.loadingDimmerActive = false
            } catch (error) {
                console.error('error looping through contactMethods:', error)
                this.changeLoadingStep('LOOP', 'error')
            }
        },

        selectAllContactMethods(){
            this.selectedContactMethods = [...this.availableContactMethods]
        },

        deselectAllContactMethods(){
            this.selectedContactMethods = []
        },

        selectContactMethod(method){
            if (this.selectedContactMethods.includes(method)){
                console.error('Contact method was already selected')
            } else {
                this.$set(this.selectedContactMethods, this.selectedContactMethods.length, method)
            }
        },

        deselectContactMethod(method){
            let index = this.selectedContactMethods.indexOf(method)
            if (index != -1) { //Method exists in array
                this.selectedContactMethods.splice(index, 1)
            } else {
                console.error('cannot deselect method that is not selected')
            }
        },

        selectAllSystemActions(){
            this.shouldAddExternalNote = true
            this.shouldAddUnitWork = true
        },

        deselectAllSystemActions(){
            this.shouldAddExternalNote = false
            this.shouldAddUnitWork = false
        },

        async send(){

            //Initialize loader
            this.loadingSteps = [
                {
                    code: 'SEND_MESSAGES',
                    title: 'Send beskeder til valgte kontakt-oplysninger',
                    state: 'pending',
                },
                {
                    code: 'ADD_EXT_NOTE',
                    title: 'Tilføj ekstern note',
                    state: 'pending',
                },
                {
                    code: 'ADD_UNITWORK',
                    title: 'Tilføj enhedsarbejde',
                    state: 'pending',
                },
            ]
            this.loadingDimmerActive = true

            //Send Messages to selected contact info
            let templateData = {
                Adresse: this.formatAddress(this.getConfiguration(this.bookTaskOrAny).address, true)
            }
            let promiseArray = []
            let sentSMS = false
            let sentEmail = false
            let sentMessageCount = 0
            for (let contactMethod of this.selectedContactMethods) {
                if (contactMethod.includes('@')){
                    promiseArray.push(
                        this.dataAddEmail({
                            template: {
                                name: 'AttemptedContact',
                                data: templateData
                            },
                            to: contactMethod,
                            bcc: this.$root.$children[0].user.email
                        }).then(() => {
                            sentEmail = true
                            sentMessageCount += 1
                            if (sentMessageCount == this.selectedContactMethods.length) {
                                this.changeLoadingStep('SEND_MESSAGES', 'success')
                            }
                        }).catch(err => {
                            console.error(err)
                            this.changeLoadingStep('SEND_MESSAGES', 'error')
                        })
                    )
                } else {
                    promiseArray.push(
                        this.dataAddSMS({
                            Template: 'AttemptedContact',
                            Data: templateData,
                            To: [this.parsePhoneNumber(contactMethod)]
                        }).then(() => {
                            sentSMS = true
                            sentMessageCount += 1
                            if (sentMessageCount == this.selectedContactMethods.length) {
                                this.changeLoadingStep('SEND_MESSAGES', 'success')
                            }
                        }).catch(err => {
                            console.error(err)
                            this.changeLoadingStep('SEND_MESSAGES', 'error')
                        })
                    )
                }
            }
            this.changeLoadingStep('SEND_MESSAGES', 'in progress')
            await Promise.allSettled(promiseArray)

            //Perform system actions
            let systemActionPromises = []

            let noteString = 'Forgæves forsøg på at kontakte kunden.'
            if (sentSMS || sentEmail) {
                let messageTypes = []
                if (sentSMS) messageTypes.push('SMS')
                if (sentEmail) messageTypes.push('Email')
                noteString += `\n${messageTypes.join(' & ')} sendt med FiberLAN's telefonnummer, med anmodning om at kunden ringer til os.`
            } else {
                noteString += '\nDer er i denne omgang ikke sendt autogenereret besked til kunden.'
            }

            if (this.shouldAddExternalNote){ //Add external note
                console.log(`Adding external note:\n'${noteString}''`)

                let taskCode = this.bookTaskOrAny.code
                let middleRequestText = (taskCode == TaskCode.TICKET ? 'TroubleTickets/Note' : 'ProjectTasks/ProjectTask')
                let endRequestText = (taskCode == TaskCode.TICKET ? '' : '/Note')

                // let middleRequestText = 'ProjectTasks/ProjectTask'
                // let endRequestText = '/Note'
                systemActionPromises.push(
                    this.dataPostNote(this.bookTaskOrAny.id, this.installation.label, noteString, 'Ekstern', this.$route.params.projectIdentifier, middleRequestText, endRequestText).then(() => {
                        console.log('Successfully posted note')
                        this.changeLoadingStep('ADD_EXT_NOTE', 'success')
                    }).catch((error) => {
                        console.error(error)
                        this.changeLoadingStep('ADD_EXT_NOTE', 'error')
                    })
                )
            }

            if (this.shouldAddUnitWork){ //Add unit work
                console.log(`Adding unitWork with description:\n${noteString}`)
                const Unit = this.unitWorkCompositeUnits.find(u => u.Id == '931.F.05a')

                let taskConfig = this.getConfiguration(this.bookTaskOrAny)

                const unitWorkDocument = {
                    AreaCode: taskConfig?.area?.sonWinProjectId || null,
                    ProjectInstallationType: (this.bookTaskOrAny.code == TaskCode.TICKET ? 'TroubleTickets' : this.bookTaskOrAny.project?.type?.value),
                    ConfigurationItem: {
                        Label: this.getConfigurationLabel(this.bookTaskOrAny) || null,
                        Area: taskConfig?.area || null,
                        Cabinet: taskConfig?.cabinet || null,
                        TechnicalData: taskConfig?.technicalData || null,
                        Type: taskConfig?.type || null,
                        Value: taskConfig?.value || null,
                        Address: this.formatAddress(taskConfig?.address, false) || null,
                    },
                    ReferenceId: this.bookTaskOrAny.referenceId || null,
                    ServiceOrder: this.bookTaskOrAny.serviceOrder || null,
                    PayedBy: "EFB",
                    Unit,
                    Amount: 1,
                    EquivalentHours: this.calculateEquivalentHours(Unit.Id, 1),
                    Description: noteString,
                    Workers: [this.$root.$children[0].user.email],
                    TimeStamp: new Date(),
                    CreatedBy: {
                        Name: this.$root.$children[0].user.displayName,
                        Email: this.$root.$children[0].user.email,
                        Initials: this.getInitialsFromEmail(this.$root.$children[0].user?.email)
                    },
                    AutoGenerated: true,
                    Date: this.formatMachineDate(new Date(), '-'),
                    Billed: {
                        Bool: false,
                        Updated: new Date()
                    },
                    LinkedProjects: [this.$route.params.projectIdentifier],
                    Price: this.calculatePrice(Unit.Id, 1),
                    FromDocumentation: {},
                }
                systemActionPromises.push(
                    this.dataAddOrUpdateUnitWork(unitWorkDocument).then(() => {
                        console.log('Successfully added unitWork')
                        this.changeLoadingStep('ADD_UNITWORK', 'success')
                    }).catch((error) => {
                        console.error(error)
                        this.changeLoadingStep('ADD_UNITWORK', 'error')
                    })
                )
            }

            // Implement business logic senerio

            // Senerio 0 (ProjectTask):
            // Do nothing

            // Senario 1 (ProjectTask):
            // Set user as assignee on the book task if a book task exists
            // Set book task as work in progress
            // If third time, set book task as on hold (VENDOR_NO_CONTACT)
            // If third time, set (preconfigured) tag on the installation

            // Senario 2 (ProjectTask):
            // Set user as assignee on the book task if a book task exists
            // Set book task on hold (VENDOR_NO_CONTACT)
            // If third time, set (preconfigured) tag on the installation

            // Senario 0 (TroubleTicket):
            // Do nothing

            // Senario 1 (TroubleTicket):
            // Set user as assignee on the ticket
            // If third time, set ticket as on hold (VENDOR_NO_CONTACT)
            // If third time, set (preconfigured) tag on the installation

            // Senario 2 (TroubleTicket):
            // Set user as assignee on the ticket
            // Set ticket as on hold (VENDOR_NO_CONTACT)
            // If third time, set (preconfigured) tag on the installation


            this.changeLoadingStep('ADD_EXT_NOTE', 'in progress')
            this.changeLoadingStep('ADD_UNITWORK', 'in progress')
            await Promise.allSettled(systemActionPromises)
            this.deselectAllContactMethods()
            this.closeModal()
            this.loadingDimmerActive = false
            return true
        },

        changeLoadingStep(code, state){
            let stepIndex = this.loadingSteps.findIndex(step => step.code == code)
            if (stepIndex == -1) {
                console.error(`Could not find step with code ${code} in this.loadingSteps`)
                return
            }
            let stepObj = this.loadingSteps[stepIndex]
            stepObj.state = state
            this.$set(this.loadingSteps, stepIndex, stepObj)
            return stepObj
        },

    },

    watch: {
        contacts: {
            deep: true,
            immediate: true,
            handler() {
                this.deselectAllContactMethods()
                if (this.isOpen) this.getAllLatestMessages()
            }
        },
        isOpen: {
            immediate: true,
            handler(isOpen){
                if (isOpen) this.getAllLatestMessages()
            }
        }
    }
}
</script>
<style scoped>
.fa-square:hover, .fa-square-check:hover, .fa-square-minus:hover {
    cursor: pointer;
}
</style>